import { ReactNode } from 'react';
import { RiLogoutCircleLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';

type Props = {
    withLogout?: boolean;
    children: ReactNode;
};

const MerchantLayout: React.FC<Props> = (props) => {
    const { withLogout, children } = props;
    const navigate = useNavigate();

    // function for handle logout
    const handleLogout = () => {
        localStorage.clear();
        navigate('/login');
    };

    return (
        <div className='h-screen grid grid-cols-1 justify-items-center content-center bg-gray-100 relative'>
            <img src='/illus_merchant.svg' alt='authentication illustration' className='w-full h-auto max-h-screen' />
            {withLogout && (
                <div
                    aria-hidden
                    className='absolute z-10 right-3 bottom-5 p-2 rounded-full cursor-pointer bg-white text-blue-600 hover:bg-blue-700 hover:text-white'
                    onClick={handleLogout}
                >
                    <RiLogoutCircleLine className='text-4xl' />
                </div>
            )}
            <div className='absolute top-0 right-0 bottom-0 left-0 flex items-center justify-center opacity-95'>
                <div className='p-6 bg-white rounded-xl shadow-2xl'>{children}</div>
            </div>
        </div>
    );
};

export default MerchantLayout;

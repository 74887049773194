import { notifications } from '@mantine/notifications';
import api from '../../../../api';
import { product_category } from '../../../../api/endpoints';
import { useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { ICategory } from '../../../../interface/Category';
import { generatePath } from 'react-router-dom';

function FormHooks(refetch: () => void, setModal: (value: boolean) => void, category?: ICategory) {
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm({
        defaultValues: {
            name: category ? category.name : '',
        },
    });
    const [loading, setLoading] = useState<boolean>(false);

    const onSubmit = async (data: FieldValues) => {
        setLoading(true);
        const newData = {
            ...data,
            merchant_id: localStorage.getItem('merchantID'),
        };

        const url = category ? generatePath(product_category.update, { id: category?.id }) : product_category.create;
        const res = category ? await api.put(url, newData) : await api.post(url, newData);
        setLoading(false);
        if (res.data.status) {
            refetch();
            setModal(false);
        } else {
            notifications.show({
                message: 'Ups, terjadi kesalahan saat submit kategori produk.',
                color: 'red',
            });
        }
    };

    return {
        register,
        errors,
        handleSubmit,
        loading,
        onSubmit,
    };
}

export default FormHooks;

// import '@mantine/dates/styles.css';
import { createContext } from 'react';
import { RouterProvider } from 'react-router-dom';
import { MerchantContext } from './context';
import router from './router';

export const AppContext = createContext({});

function App() {
    const { merchants, setMerchants, selectedMerchantId, setSelectedMerchantId } = MerchantContext();

    return (
        <AppContext.Provider value={{ merchants, setMerchants, selectedMerchantId, setSelectedMerchantId }}>
            <RouterProvider router={router} />
        </AppContext.Provider>
    );
}

export default App;

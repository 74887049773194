import { notifications } from '@mantine/notifications';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import api from '../../api';
import { products } from '../../api/endpoints';

const defaultValue = {
    current_page: 1,
    data: [],
    last_page: 1,
};

function ProductsHooks() {
    const [params, setParams] = useState({
        merchant_id: localStorage.getItem('merchantID'),
        page: 1,
        per_page: 10,
        q: '',
    });

    const {
        data: productData = defaultValue,
        isFetching,
        refetch,
    } = useQuery(['product', params], async () => {
        const res = await api.get(products.fetch, {
            params: params,
        });

        if (!res?.data?.status) {
            notifications.show({
                message: 'Ups, terjadi kesalahan saat fetch detail merchant.',
                color: 'red',
            });
        }
        return res?.data;
    });

    return {
        params,
        setParams,
        productData,
        isFetching,
        refetch,
    };
}

export default ProductsHooks;

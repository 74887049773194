import { Anchor, Breadcrumbs, Button, Divider, Image, Skeleton, Table } from '@mantine/core';
import dayjs from 'dayjs';
import numeral from 'numeral';
import { TbBaguette } from 'react-icons/tb';
import { Link } from 'react-router-dom';
import { TransactionStatusBadge } from '../../../components';
import DetailHooks from './DetailHooks';
import Accept from './accept';
import Cancel from './cancel';

const DetailTransaction = () => {
    const { code, transactionDetail, isFetching, refetch } = DetailHooks();

    return (
        <div className='flex flex-col gap-4 pb-[65px]'>
            <div className='flex justify-between items-center'>
                <Link to={`/app/transaction`}>
                    <Button variant='default' className='text-inherit font-normal bg-white'>
                        Kembali
                    </Button>
                </Link>
                <Breadcrumbs className='text-sm text-gray-500'>
                    <Anchor style={{ color: 'inherit' }} component={Link} to={`/app/transaction`}>
                        Transaksi
                    </Anchor>
                    <div>Detail</div>
                    <div>{code}</div>
                </Breadcrumbs>
            </div>

            <div className='text-lg font-medium'>Detail Transaksi</div>

            <div className='rounded bg-white p-6 shadow-[0_6px_18px_rgba(0,0,0,0.06)]'>
                <div className='text-xl font-medium flex gap-2 items-center'>
                    <div>{code}</div>
                    <TransactionStatusBadge status={transactionDetail?.data?.status ?? ''} />
                </div>
                <Divider className='my-5' />
                <div className='flex flex-col gap-3'>
                    {/* dibuat oleh */}
                    <div className='grid grid-cols-12 text-sm'>
                        <div className='col-span-3 text-gray-500'>Dibuat Oleh</div>
                        <div className='col-span-9'>{transactionDetail?.data?.created_by?.name ?? '-'}</div>
                    </div>
                    {/* tanggal transaksi */}
                    <div className='grid grid-cols-12 text-sm'>
                        <div className='col-span-3 text-gray-500'>Tanggal Transaksi</div>
                        <div className='col-span-9'>
                            {dayjs(transactionDetail?.data?.created_at).format('D MMMM YYYY, HH:mm')}
                        </div>
                    </div>
                    {/* customer */}
                    <div className='grid grid-cols-12 text-sm'>
                        <div className='col-span-3 text-gray-500'>Customer</div>
                        <div className='col-span-9'>{transactionDetail?.data?.customer_name ?? '-'}</div>
                    </div>
                </div>
            </div>

            <div className='rounded bg-white p-6 shadow-[0_6px_18px_rgba(0,0,0,0.06)] flex flex-col gap-5'>
                <div className='text-lg font-medium'>Daftar Produk</div>
                <Divider />

                {/* list items */}
                <Table verticalSpacing='sm' horizontalSpacing='md' withBorder>
                    <thead>
                        <tr>
                            <th>Gambar</th>
                            <th>Nama Produk</th>
                            <th>Kuantitas</th>
                            <th className='!text-right'>Harga Satuan</th>
                            <th className='!text-right'>Subtotal</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isFetching ? (
                            <tr className='bg-white mb-2 rounded-table-cell shadow-md'>
                                <td colSpan={6} className='rounded-lg'>
                                    <Skeleton height={55} radius='md' />
                                </td>
                            </tr>
                        ) : (
                            transactionDetail?.data?.items.map((item: any, key: number) => (
                                <tr key={key}>
                                    <td>
                                        <Image
                                            width={40}
                                            height={40}
                                            fit='cover'
                                            src={item?.product_image}
                                            withPlaceholder
                                            placeholder={<TbBaguette className='text-3xl' />}
                                            radius='md'
                                        />
                                    </td>
                                    <td>{item?.product_name}</td>
                                    <td>{item?.qty}</td>
                                    <td className='text-right'>Rp {numeral(item?.price).format()}</td>
                                    <td className='text-right font-semibold'>
                                        Rp {numeral(item?.qty * item?.price).format()}
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </Table>

                <div className='px-4'>
                    <div className='flex flex-col justify-end gap-2'>
                        {/* total harga produk */}
                        <div className='flex justify-end'>
                            <div className='flex justify-between gap-2 text-sm w-[37%] float-right'>
                                <div className='text-gray-500'>Total Harga Produk</div>
                                <div className='font-semibold text-right'>
                                    Rp {numeral(transactionDetail?.data?.total_price).format()}
                                </div>
                            </div>
                        </div>
                        {/* potongan harga */}
                        <div className='flex justify-end'>
                            <div className='flex justify-between gap-2 text-sm w-[37%]'>
                                <div className='text-gray-500'>Potongan Harga</div>
                                <div className='font-semibold text-right text-red-600'>
                                    - Rp {numeral(transactionDetail?.data?.discount).format()}
                                </div>
                            </div>
                        </div>
                        <div className='flex justify-end'>
                            <Divider className='border-dashed my-1 w-[37%]' />
                        </div>
                        {/* total */}
                        <div className='flex justify-end'>
                            <div className='flex justify-between text-sm w-[37%]'>
                                <div className='text-gray-500 font-bold'>Total</div>
                                <div className='font-semibold text-right'>
                                    Rp{' '}
                                    {numeral(
                                        transactionDetail?.data?.total_price - transactionDetail?.data?.discount
                                    ).format()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* detail payment transaction */}
            {transactionDetail?.data?.total_payment > 0 && (
                <div className='rounded bg-white p-6 shadow-[0_6px_18px_rgba(0,0,0,0.06)]'>
                    <div className='p-4 bg-blue-50 border-y border-solid'>
                        <div className='flex flex-col justify-end gap-2'>
                            {/* total pembayaran */}
                            <div className='flex justify-end'>
                                <div className='flex justify-between gap-2 text-sm w-[37%] float-right'>
                                    <div className='text-gray-500 font-bold'>Total Pembayaran</div>
                                    <div className='font-semibold text-right'>
                                        Rp {numeral(transactionDetail?.data?.total_payment).format()}
                                    </div>
                                </div>
                            </div>
                            {/* Kembalian */}
                            <div className='flex justify-end'>
                                <div className='flex justify-between gap-2 text-sm w-[37%]'>
                                    <div className='text-gray-500 font-bold'>Kembalian</div>
                                    <div className='font-semibold text-right'>
                                        Rp{' '}
                                        {numeral(
                                            transactionDetail?.data?.total_payment -
                                                (transactionDetail?.data?.total_price -
                                                    transactionDetail?.data?.discount)
                                        ).format()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* action for transaction */}
            {transactionDetail?.data?.status === 'PROCESSING' && (
                <div className='rounded px-6 py-4 bg-white flex justify-end shadow-[0_6px_18px_rgba(0,0,0,0.06)] gap-2'>
                    <Cancel refetch={refetch} />
                    <Accept
                        products={transactionDetail?.data?.items}
                        total_qty={transactionDetail?.data?.total_item}
                        sub_total={transactionDetail?.data?.total_price}
                        discount={transactionDetail?.data?.discount}
                        refetch={refetch}
                    />
                </div>
            )}
        </div>
    );
};

export default DetailTransaction;

import { notifications } from '@mantine/notifications';
import { useQuery } from '@tanstack/react-query';
import api from '../../api';
import { merchant } from '../../api/endpoints';

function UserHooks() {
    // fetching transaction report
    const {
        data: user,
        isFetching,
        refetch,
    } = useQuery(['user'], async () => {
        const res = await api.get(merchant.employee, {
            params: {
                merchant_id: localStorage.getItem('merchantID'),
            },
        });

        if (!res?.data?.status) {
            notifications.show({
                message: 'Ups, terjadi kesalahan saat fetch laporan transaksi.',
                color: 'red',
            });
        }
        return res?.data;
    });

    return {
        user,
        isFetching,
        refetch,
    };
}

export default UserHooks;

import { Button, Modal } from '@mantine/core';
import { MdOutlineDeleteOutline } from 'react-icons/md';
import { RiDeleteBinLine } from 'react-icons/ri';
import DeleteHooks from './DeleteHooks';

type Props = {
    productName: string;
    productID: number;
    refetch: () => void;
};

const Delete: React.FC<Props> = (props) => {
    const { productName, productID, refetch } = props;
    const { modal, setModal, loading, handleDelete } = DeleteHooks(refetch);

    return (
        <>
            <RiDeleteBinLine
                aria-hidden
                className='text-lg text-red-700 cursor-pointer'
                onClick={() => setModal(true)}
            />

            <Modal
                opened={modal}
                onClose={() => setModal(false)}
                withCloseButton={false}
                radius='lg'
                size={350}
                padding={30}
                centered
            >
                <MdOutlineDeleteOutline className='text-8xl mx-auto text-red-600 mb-1' />
                <div className='text-center text-lg font-semibold mb-1'>Anda yakin akan menghapus produk ini?</div>
                <div className='text-center text-sm text-gray-500 mb-4'>{productName}</div>
                <div className='grid grid-cols-2 gap-3'>
                    <Button variant='outline' onClick={() => setModal(false)}>
                        Batal
                    </Button>
                    <Button
                        color='red'
                        className='bg-red-600'
                        loading={loading}
                        onClick={() => handleDelete(productID)}
                    >
                        Hapus
                    </Button>
                </div>
            </Modal>
        </>
    );
};

export default Delete;

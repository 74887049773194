import { DatesRangeValue } from '@mantine/dates';
import { notifications } from '@mantine/notifications';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { ITransactionParams } from 'interface/Transaction';
import { useState } from 'react';
import api from '../../api';
import { transaction } from '../../api/endpoints';

const defaultValue = {
    current_page: 1,
    data: [],
    last_page: 1,
};

const defaultParams = {
    page: 1,
    per_page: 10,
    status: 'PROCESSING',
};

function TransactionHooks() {
    const merchant_id = parseInt(localStorage.getItem('merchantID') ?? '0');
    const [params, setParams] = useState<ITransactionParams>(defaultParams);

    // fetching transaction
    const {
        data: transactionData = defaultValue,
        isFetching,
        refetch,
    } = useQuery(['transaction', params, merchant_id], async () => {
        const res = await api.get(transaction.fetch, {
            params: {
                ...params,
                merchant_id: merchant_id,
            },
        });

        if (!res?.data?.status) {
            notifications.show({
                message: 'Ups, terjadi kesalahan saat fetch detail merchant.',
                color: 'red',
            });
        }
        return res?.data;
    });

    // handle change date filter
    const handleChangeDate = (value: DatesRangeValue) => {
        const start_date = dayjs(value[0]).format('YYYY-MM-D');
        const end_date = dayjs(value[1]).format('YYYY-MM-D');

        // set params if value is not null
        value[0] &&
            value[1] &&
            setParams({
                ...params,
                start_date: start_date,
                end_date: end_date,
            });

        // set params if both of value null
        !value[0] &&
            !value[1] &&
            setParams({ ...defaultParams, status: params.status, page: params.page, per_page: params.per_page });
    };

    return {
        params,
        setParams,
        transactionData,
        isFetching,
        refetch,
        handleChangeDate,
    };
}

export default TransactionHooks;

import { useState } from 'react';

function CreateHooks() {
    const [modal, setModal] = useState<boolean>(false);

    return {
        modal,
        setModal,
    };
}

export default CreateHooks;

import { Modal } from '@mantine/core';
import { BiPencil } from 'react-icons/bi';
import { GrFormClose } from 'react-icons/gr';
import EditHooks from './EditHooks';
import Form from '../form';
import { IProduct } from '../../../interface/Product';

type Props = {
    data: IProduct;
    refetch: () => void;
};

const Edit: React.FC<Props> = (props) => {
    const { data, refetch } = props;
    const { modal, setModal } = EditHooks();

    return (
        <>
            <BiPencil className='text-lg text-blue-700 cursor-pointer' onClick={() => setModal(true)} />

            <Modal
                opened={modal}
                onClose={() => setModal(false)}
                withCloseButton={false}
                padding={20}
                radius='md'
                size={400}
            >
                <div className='relative'>
                    <div className='font-semibold text-xl text-center mb-5'>Edit Produk</div>
                    <GrFormClose
                        className='absolute -right-2 -top-1 text-xl cursor-pointer'
                        onClick={() => setModal(false)}
                    />
                </div>
                <Form data={data} refetch={refetch} handleClose={() => setModal(false)} />
            </Modal>
        </>
    );
};

export default Edit;

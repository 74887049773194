import { Modal } from '@mantine/core';
import { BiPencil } from 'react-icons/bi';
import { GrFormClose } from 'react-icons/gr';
import EditHooks from './EditHooks';
import Form from '../form';
import { ICategory } from '../../../../interface/Category';

type Props = {
    data: ICategory;
    refetch: () => void;
};

const Edit: React.FC<Props> = (props) => {
    const { data, refetch } = props;
    const { modal, setModal } = EditHooks();

    return (
        <div>
            <BiPencil className='text-blue-600 cursor-pointer' onClick={() => setModal(true)} />

            <Modal
                opened={modal}
                onClose={() => setModal(false)}
                withCloseButton={false}
                padding={0}
                radius='md'
                size={350}
            >
                <div className='flex justify-between items-center px-5 py-4 border-b'>
                    <div className='text-lg font-semibold'>Edit Kategori</div>
                    <GrFormClose className='cursor-pointer' onClick={() => setModal(false)} />
                </div>
                <Form data={data} refetch={refetch} setModal={setModal} />
            </Modal>
        </div>
    );
};

export default Edit;

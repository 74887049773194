import { Button, Modal } from '@mantine/core';
import CancelHooks from './CancelHooks';

type Props = {
    refetch: () => void;
};

const Cancel: React.FC<Props> = (props) => {
    const { refetch } = props;
    const { code, modal, setModal, loading, handleCancelTransaction } = CancelHooks(refetch);

    return (
        <div>
            <Button variant='outline' onClick={() => setModal(true)}>
                Batal
            </Button>

            <Modal opened={modal} onClose={() => setModal(false)} withCloseButton={false} padding={0} size={400}>
                <div className='px-6 py-4 font-semibold text-xl text-center bg-blue-50'>Konfirmasi Pembatalan</div>
                <div className='px-6 py-4 flex flex-col gap-4'>
                    <div className='text-gray-600 text-center'>
                        Apakah anda yakin akan membatalkan transaksi{' '}
                        <span className='font-semibold text-black'>{code}</span> ?
                    </div>
                    <div className='flex flex-col gap-2'>
                        <Button color='red' className='bg-red-600' onClick={handleCancelTransaction} loading={loading}>
                            Konfirmasi Pembatalan
                        </Button>
                        <Button variant='outline' onClick={() => setModal(false)}>
                            Batal
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default Cancel;

export const auth = {
    register: '/v1/register',
    login: '/v1/login',
    verifyOTP: '/v1/verify-otp',
    resendOTP: '/v1/resend-otp',
};

export const merchant = {
    fetchAll: '/v1/merchants',
    findByID: '/v1/merchants/:id',
    create: '/v1/merchants',
    update: '/v1/merchants/:id',
    employee: '/v1/my-employee',
};

export const upload = {
    image: '/v1/upload-image',
};

export const product_category = {
    fetch: '/v1/categories',
    create: '/v1/categories',
    update: '/v1/categories/:id',
};

export const products = {
    fetch: '/v1/products',
    create: '/v1/product',
    update: '/v1/product/:id',
    delete: '/v1/product/:id',
};

export const transaction = {
    fetch: '/v1/transactions',
    create: '/v1/transactions',
    cancel: '/v1/transaction/cancelled',
    accept: '/v1/transaction/completed',
    detail: '/v1/transactions/:code',
    report: '/v1/transaction/report',
    report_summary: '/v1/transaction/report-summary',
    update_item: '/v1/transaction/update-item',
};

export const user = {
    create: '/v1/create-users',
    delete: '/v1/users/:id',
};

import React, { ChangeEvent } from 'react';
import { Image } from '@mantine/core';
import { BsUpload } from 'react-icons/bs';
import { MdOutlineImageNotSupported } from 'react-icons/md';

type Props = {
    src: string;
    accept: string;
    handleChange: (value: File) => void;
};

const UploadImage: React.FC<Props> = (props) => {
    const { src, accept, handleChange } = props;

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const file = e?.target?.files?.[0];
        file && handleChange(file);
    };

    return (
        <div className='relative flex items-center w-[130px] h-[130px] border border-dashed rounded p-2 border-gray-400'>
            {src !== '' ? (
                <Image
                    src={src}
                    width='100%'
                    height={114}
                    withPlaceholder
                    placeholder={<MdOutlineImageNotSupported className='text-5xl' />}
                />
            ) : (
                <div className='w-full text-xs text-gray-500'>
                    <BsUpload className='ml-auto mr-auto mb-1 text-lg' />
                    <div className='text-xs text-center'>Upload Gambar</div>
                </div>
            )}
            <input
                type='file'
                accept={accept}
                className='opacity-0 z-10 absolute top-0 bottom-0 left-0 right-0 cursor-pointer'
                onChange={onChange}
            />
        </div>
    );
};

export default UploadImage;

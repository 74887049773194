import { Badge } from '@mantine/core';

type Props = {
    status: string;
};

const TransactionStatusBadge: React.FC<Props> = (props) => {
    const { status } = props;

    if (status === 'PROCESSING') {
        return <Badge color='green'>Diproses</Badge>;
    }

    if (status === 'COMPLETED') {
        return <Badge>Selesai</Badge>;
    }

    if (status === 'CANCELLED') {
        return <Badge color='red'>Dibatalkan</Badge>;
    }

    return <Badge color='gray'>Menunggu Diproses</Badge>;
};

export default TransactionStatusBadge;

import { notifications } from '@mantine/notifications';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import api from '../../api';
import { merchant } from '../../api/endpoints';

function MerchantHooks() {
    const navigate = useNavigate();
    const { data: merchantData = [], isFetching } = useQuery(['merchant'], async () => {
        const res = await api.get(merchant.fetchAll);

        if (!res?.data?.status) {
            notifications.show({
                message: 'Ups, terjadi kesalahan saat mengakses data merchant.',
                color: 'red',
            });
            return [];
        }
        return res.data.data;
    });

    // function for handle select merchant
    const handleSelectMerchant = (id: number) => {
        localStorage.setItem('merchantID', String(id));
        navigate('/app/dashboard');
    };

    return {
        merchantData,
        isFetching,
        handleSelectMerchant,
    };
}

export default MerchantHooks;

import { Breadcrumbs, Pagination, Select, Skeleton, Table } from '@mantine/core';
// import { DatePickerInput } from '@mantine/dates';
import { DatePickerInput } from '@mantine/dates';
import dayjs from 'dayjs';
import numeral from 'numeral';
import { Link } from 'react-router-dom';
import { TransactionStatusBadge } from '../../components';
import { IListTransaction } from '../../interface/Transaction';
import TransactionHooks from './TransactionHooks';

const Transaction = () => {
    const { params, setParams, transactionData, isFetching, handleChangeDate } = TransactionHooks();

    return (
        <div className='flex flex-col'>
            <div className='flex justify-between'>
                <div className='text-xl font-semibold py-4'>Transaksi</div>
                <Breadcrumbs className='text-sm text-gray-600'>
                    <div>App</div>
                    <div>Transaksi</div>
                </Breadcrumbs>
            </div>
            <div className='flex flex-col gap-2 pb-[65px]'>
                <div className='flex justify-between items-center p-4 bg-white rounded-md shadow-md'>
                    <div className='flex gap-2'>
                        <div
                            aria-hidden
                            className={`py-2 px-5 w-fit ${
                                params.status === 'PROCESSING' ? 'bg-blue-700 text-white' : 'bg-white'
                            } font-semibold text-center text-sm whitespace-nowrap rounded-md cursor-pointer shadow-md hover:bg-blue-200`}
                            onClick={() => setParams({ ...params, status: 'PROCESSING' })}
                        >
                            DIPROSES
                        </div>
                        <div
                            aria-hidden
                            className={`py-2 px-5 w-fit ${
                                params.status === 'COMPLETED' ? 'bg-blue-700 text-white' : 'bg-white'
                            } font-semibold text-center text-sm whitespace-nowrap rounded-md cursor-pointer shadow-md hover:bg-blue-200`}
                            onClick={() => setParams({ ...params, status: 'COMPLETED' })}
                        >
                            SELESAI
                        </div>
                        <div
                            aria-hidden
                            className={`py-2 px-5 w-fit ${
                                params.status === 'CANCELLED' ? 'bg-blue-700 text-white' : 'bg-white'
                            } font-semibold text-center text-sm whitespace-nowrap rounded-md cursor-pointer shadow-md hover:bg-blue-200`}
                            onClick={() => setParams({ ...params, status: 'CANCELLED' })}
                        >
                            DIBATALKAN
                        </div>
                    </div>
                    <DatePickerInput
                        type='range'
                        valueFormat='D MMMM YYYY'
                        placeholder='Tanggal Awal - Tanggal Akhir'
                        onChange={(value) => handleChangeDate(value)}
                        miw={250}
                        className='bg-white border-0'
                        clearable
                    />
                </div>

                <Table
                    verticalSpacing='md'
                    horizontalSpacing='lg'
                    className='border-separate border-spacing-x-0 border-spacing-y-2 [&>thead>tr>th]:border-b-0'
                >
                    <thead>
                        <tr className='bg-white shadow-md'>
                            <th className='rounded-l-lg'>Kode Transaksi</th>
                            <th className='!text-center'>Total Item</th>
                            <th>Transaksi</th>
                            <th>Tanggal Transaksi</th>
                            <th>Customer</th>
                            <th className='!text-center'>Status</th>
                            <th className='!text-center rounded-r-lg'>Aksi</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* loading fetching data */}
                        {isFetching && (
                            <tr className='bg-white mb-2 rounded-table-cell shadow-md'>
                                <td colSpan={6} className='rounded-lg'>
                                    <Skeleton height={54} radius='md' />
                                </td>
                            </tr>
                        )}

                        {/* list data */}
                        {!isFetching &&
                            transactionData.data.length > 0 &&
                            transactionData.data.map((datum: IListTransaction, key: number) => (
                                <tr className='shadow-md bg-white [&>td]:align-top' key={key}>
                                    <td className='rounded-l-lg'>
                                        <div className='mb-1 font-semibold'>{datum?.code}</div>
                                        <div className='text-xs text-gray-500'>
                                            dibuat oleh {datum?.created_by?.name ?? '-'}
                                        </div>
                                    </td>
                                    <td className='text-center'>{datum?.total_item}</td>
                                    <td>
                                        <div className='mb-1'>
                                            Total Harga:{' '}
                                            <span className='font-medium'>
                                                Rp {numeral(datum?.total_price).format()}
                                            </span>
                                        </div>
                                        <div className='mb-1 text-red-600'>
                                            Diskon:{' '}
                                            <span className='font-medium'>Rp {numeral(datum?.discount).format()}</span>
                                        </div>
                                        <div>
                                            Jumlah Pembayaran:{' '}
                                            <span className='font-medium'>
                                                Rp {numeral(datum?.total_payment).format()}
                                            </span>
                                        </div>
                                    </td>
                                    <td className='rounded-r-lg'>
                                        {dayjs(datum?.created_at).format('D MMMM YYYY, HH:mm')}
                                    </td>
                                    <td>{datum?.customer_name ?? '-'}</td>
                                    <td className='text-center'>
                                        <TransactionStatusBadge status={datum?.status} />
                                    </td>
                                    <td className='text-center rounded-r-lg'>
                                        <Link to={`/app/transaction/detail/${datum?.code}`} className='text-blue-700'>
                                            Detail
                                        </Link>
                                    </td>
                                </tr>
                            ))}

                        {/* empty state */}
                        {!isFetching && transactionData.data.length === 0 && (
                            <tr>
                                <td colSpan={6} className='bg-white rounded-lg shadow-md'>
                                    <img src='/empty_illustation.svg' alt='empty data' className='ml-auto mr-auto' />
                                    <div className='text-center font-medium text-lg'>Belum memiliki transaksi</div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>

                {/* pagination and limit option */}
                {!isFetching && transactionData?.data?.length > 0 && (
                    <div className='flex justify-between items-center'>
                        <div className='text-sm text-gray-500'>
                            <div>
                                <Select
                                    type='text'
                                    styles={{
                                        root: {
                                            display: 'inline-block',
                                            width: 70,
                                        },
                                        input: {
                                            textAlign: 'center',
                                        },
                                    }}
                                    value={String(params.per_page)}
                                    data={['10', '25', '50', '100']}
                                    className='mr-2'
                                    onChange={(value) => {
                                        setParams({ ...params, per_page: parseInt(value || '10') });
                                    }}
                                />
                                Menampilkan {transactionData.data.length} data
                            </div>
                        </div>
                        <div>
                            <Pagination
                                value={params.page}
                                total={transactionData?.last_page}
                                onChange={(value: number) => setParams({ ...params, page: value })}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Transaction;

import { notifications } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import api from '../../api';
import { auth } from '../../api/endpoints';

function RegisterHooks() {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [time, setTime] = useState<number>(10);
    const [otp, setOtp] = useState<string>('');
    const [loading, setLoading] = useState<'register' | 'otp' | ''>('');

    useEffect(() => {
        const interval = setInterval(() => {
            if (searchParams.get('segment') === 'otp' && time !== 0) {
                setTime(time - 1);
            }

            // clear interval after react 0
            if (time === 0) {
                clearInterval(interval);
            }
        }, 1000);

        // clear interval
        return () => {
            clearInterval(interval);
        };
    }, [time, searchParams]);

    // function for handle input registration data
    const handleSubmitRegistrationData = handleSubmit(async (data) => {
        setLoading('register');
        const res = await api.post(auth.register, data);

        setLoading('');
        if (res?.data?.status) {
            setSearchParams({ segment: 'otp', email: data?.email });
        } else {
            notifications.show({
                message: 'Ups, terjadi kesalahan saat registrasi.',
                color: 'red',
            });
        }
    });

    // function for handle change input otp
    const handleChangeOTP = (value: string) => {
        setOtp(value);
    };

    // function for handle input verify otp
    const handleSubmitOTP = async () => {
        const payload = {
            email: searchParams.get('email'),
            otp: otp,
        };

        setLoading('otp');
        const res = await api.post(auth.verifyOTP, payload);

        setLoading('');
        if (res?.data?.status) {
            setOtp('');
            setTime(300);
            navigate('/login');
        } else {
            notifications.show({
                message: 'Ups, terjadi kesalahan saat registrasi.',
                color: 'red',
            });
        }
    };

    // function for handle resend otp
    const handleResendOTP = async () => {
        const payload = {
            email: searchParams.get('email'),
        };

        const res = await api.post(auth.resendOTP, payload);

        if (res?.data?.status) {
            setOtp('');
            setTime(300);
        } else {
            notifications.show({
                message: 'Ups, terjadi kesalahan saat registrasi.',
                color: 'red',
            });
        }
    };

    return {
        register,
        handleSubmit,
        errors,
        searchParams,
        time,
        otp,
        loading,
        handleSubmitRegistrationData,
        handleChangeOTP,
        handleSubmitOTP,
        handleResendOTP,
    };
}

export default RegisterHooks;

import MerchantLayout from '../../../layout/merchant';
import Form from '../form';

const CreateMerchant = () => {
    return (
        <MerchantLayout>
            <div className='font-semibold text-center text-2xl mb-5'>Tambah Toko</div>
            <Form />
        </MerchantLayout>
    );
};

export default CreateMerchant;

import { Button, Divider, Image, Input, Modal, NumberInput, Skeleton, Tooltip } from '@mantine/core';
import dayjs from 'dayjs';
import numeral from 'numeral';
import { Controller } from 'react-hook-form';
import { AiFillCheckCircle, AiFillMinusCircle, AiFillPlusCircle } from 'react-icons/ai';
import { BsCalculator } from 'react-icons/bs';
import { FaCartPlus, FaShoppingCart } from 'react-icons/fa';
import { HiArchiveBoxXMark } from 'react-icons/hi2';
import { TbBaguette } from 'react-icons/tb';
import { ICategory } from '../../interface/Category';
import { IProduct } from '../../interface/Product';
import { IListTransaction } from '../../interface/Transaction';
import CashierHooks from './CashierHooks';

const Cashier = () => {
    const {
        defaultParams,
        activeTab,
        modal,
        setModal,
        cart,
        data,
        loading,
        params,
        setParams,
        control,
        register,
        watch,
        handleSubmit,
        errors,
        onGoingTransaction,
        isFetchingDetailTransaction,
        productCategory,
        productData,
        isFetchingProduct,
        handleChangeTab,
        handleAddToCart,
        handleIncreaseQty,
        handleDecreaseQty,
        generateTotalItem,
        generateSubTotal,
        handleDirectPay,
        handleSubmitTransaction,
        handleUpdaeTransaction,
    } = CashierHooks();

    return (
        <>
            <div
                aria-hidden
                className='absolute right-3 bottom-4 text-4xl p-3 rounded-full bg-white shadow-md cursor-pointer text-blue-500 hover:bg-blue-700 hover:text-white'
                onClick={() => setModal('cashier')}
            >
                <BsCalculator />
            </div>

            {/* modal cashier */}
            <Modal
                opened={modal === 'cashier'}
                onClose={() => setModal('')}
                radius='lg'
                centered
                withCloseButton={false}
                size={1200}
                padding={0}
            >
                <div className='flex flex-nowrap w-full overflow-x-auto no-scrollbar gap-1.5 bg-gray-50 pb-2'>
                    <div
                        className={`py-3 px-5 shadow-md ${
                            activeTab === '' ? 'bg-blue-700 text-white hover:text-black' : 'bg-white'
                        } font-semibold cursor-pointer whitespace-nowrap text-sm hover:bg-blue-100 flex items-center gap-2`}
                        onClick={() => handleChangeTab('')}
                    >
                        <FaCartPlus /> TRANSAKSI BARU
                    </div>
                    {/* list on going transaction code as tab */}
                    {onGoingTransaction?.data?.map((datum: IListTransaction, key: number) => (
                        <Tooltip
                            label={
                                <div>
                                    {/* total item */}
                                    <div>Total Item: {datum?.total_item}</div>
                                    {/* tanggal transaksi */}
                                    <div>
                                        Tanggal Transaksi: {dayjs(datum?.created_at).format('D MMMM YYYY, HH:mm')}
                                    </div>
                                </div>
                            }
                            color='blue'
                            withArrow
                            key={key}
                        >
                            <div
                                aria-hidden
                                className={`py-3 px-5 shadow-md ${
                                    activeTab === datum?.code ? 'bg-blue-700 text-white hover:text-black' : 'bg-white'
                                } font-semibold cursor-pointer whitespace-nowrap text-sm hover:bg-blue-100 flex items-center gap-2`}
                                onClick={() => handleChangeTab(datum?.code)}
                            >
                                <FaShoppingCart /> {datum?.code}{' '}
                                {datum?.customer_name ? `- ${datum?.customer_name}` : ''}
                            </div>
                        </Tooltip>
                    ))}
                </div>
                <div className='grid grid-cols-12 bg-gray-50'>
                    {/* left section */}
                    <div className='col-span-8'>
                        <div className='flex flex-nowrap gap-3 overflow-x-auto no-scrollbar w-full pb-4 pt-3 px-6'>
                            <div
                                className={`py-3 px-7 ${
                                    !params.category_id ? 'bg-blue-700 text-white' : 'bg-white'
                                } font-semibold text-center text-sm whitespace-nowrap rounded-md cursor-pointer shadow-md hover:bg-blue-200`}
                                onClick={() => setParams(defaultParams)}
                            >
                                SEMUA
                            </div>
                            {productCategory?.length > 0 &&
                                productCategory?.map((datum: ICategory, key: number) => (
                                    <div
                                        key={key}
                                        className={`py-3 px-7 ${
                                            params.category_id === datum?.id ? 'bg-blue-700 text-white' : 'bg-white'
                                        } font-semibold text-center text-sm whitespace-nowrap rounded-md cursor-pointer shadow-md hover:bg-blue-200`}
                                        onClick={() => setParams({ ...params, category_id: datum?.id })}
                                    >
                                        {datum?.name.toUpperCase()}
                                    </div>
                                ))}
                        </div>
                        <div className='flex justify-between items-end px-6 py-1'>
                            <div className='font-semibold text-xl'>Produk</div>
                            <div className='text-sm text-gray-500'>Menampilkan {productData?.data?.length} produk</div>
                        </div>
                        <div className='grid grid-cols-3 gap-4 max-h-[700px] overflow-auto px-8 py-3'>
                            {/* loading */}
                            {isFetchingProduct && (
                                <>
                                    <Skeleton visible={isFetchingProduct}>
                                        <div className='h-[104px]'></div>
                                    </Skeleton>
                                    <Skeleton visible={isFetchingProduct}>
                                        <div className='h-[104px]'></div>
                                    </Skeleton>
                                </>
                            )}

                            {/* empty state */}
                            {!isFetchingProduct && productData?.data?.length === 0 && (
                                <div className='col-span-3 py-6 flex flex-col gap-1 bg-white rounded-md shadow'>
                                    <HiArchiveBoxXMark className='text-7xl text-gray-500 mx-auto' />
                                    <div className='flex flex-col gap-0.5'>
                                        <div className='text-center font-semibold text-gray-500 text-lg'>
                                            Belum memiliki produk pada kategori ini.
                                        </div>
                                        <div className='text-center font-semibold text-gray-400 text-sm'>
                                            Tambahkan produk baru pada menu produk.
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* list product */}
                            {!isFetchingProduct &&
                                productData?.data?.map((product: IProduct, key: number) => (
                                    <div
                                        className={`flex flex-col p-3 shadow gap-2 rounded-md relative transition ease-in-out hover:-translate-y-1 hover:scale-105 duration-300 ${
                                            product?.stock === 0 ? 'bg-red-100' : 'bg-white'
                                        }`}
                                        key={key}
                                    >
                                        <Image
                                            width='100%'
                                            height={150}
                                            fit='cover'
                                            src={product?.image}
                                            withPlaceholder
                                            placeholder={<TbBaguette className='text-3xl' />}
                                            radius='md'
                                            className='shadow'
                                        />
                                        <div className='flex flex-col'>
                                            <div className='font-medium text-center'>{product?.name}</div>
                                            <div>
                                                {/* empty stock */}
                                                {product?.stock === 0 && (
                                                    <div className='text-red-600 text-sm text-center'>Habis</div>
                                                )}

                                                {/* ready stock */}
                                                {product?.stock > 0 && (
                                                    <div
                                                        className={`text-sm ${
                                                            product?.stock < 10 ? 'text-red-600' : 'text-gray-500'
                                                        } text-center`}
                                                    >
                                                        {product?.stock} item tersedia
                                                    </div>
                                                )}
                                            </div>
                                            <div className='text-sm text-center text-blue-600'>
                                                Rp{' '}
                                                <span className='font-semibold'>
                                                    {numeral(product?.selling_price).format()}
                                                </span>
                                            </div>
                                        </div>
                                        {!isFetchingDetailTransaction &&
                                            product?.stock > 0 &&
                                            !cart.find((each) => each.id === product?.id) && (
                                                <div className='absolute right-2 bottom-2'>
                                                    <div className='text-2xl text-blue-700'>
                                                        <AiFillPlusCircle
                                                            className='cursor-pointer'
                                                            onClick={() => {
                                                                handleAddToCart({
                                                                    id: product?.id,
                                                                    image: product?.image,
                                                                    name: product?.name,
                                                                    qty: 1,
                                                                    price: product?.selling_price,
                                                                    stock: product?.stock,
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                    </div>
                                ))}
                        </div>
                    </div>

                    {/* right section */}
                    <div className='col-span-4 p-6 bg-white'>
                        <div className='font-semibold text-xl mb-3'>Detail Pesanan</div>
                        <div className='h-[270px] overflow-auto mb-3'>
                            {/* loading */}
                            {isFetchingDetailTransaction && <Skeleton height={60} />}

                            {/* render list items on cart */}
                            {!isFetchingDetailTransaction &&
                                cart.map((each, key) => (
                                    <div className='flex gap-4 py-3 border-b border-solid relative' key={key}>
                                        <div>
                                            <Image
                                                width={40}
                                                height={40}
                                                fit='cover'
                                                src={each.image}
                                                withPlaceholder
                                                placeholder={<TbBaguette className='text-3xl' />}
                                                radius='md'
                                                className='shadow'
                                            />
                                        </div>
                                        <div>
                                            <div className='text-sm font-semibold mb-1'>{each.name}</div>
                                            <div className='text-xs font-semibold text-gray-500'>
                                                Rp {numeral(each.price * each.qty).format()}
                                            </div>
                                        </div>
                                        <div className='absolute right-1 bottom-3 flex items-center gap-2'>
                                            <div className='text-xl text-red-600'>
                                                <AiFillMinusCircle
                                                    className='cursor-pointer'
                                                    onClick={() => handleDecreaseQty(each.id)}
                                                />
                                            </div>
                                            <div className='text-sm'>{each.qty}</div>
                                            <div
                                                className={`text-xl ${
                                                    each.qty === each.stock ? 'text-gray-400' : 'text-blue-600'
                                                }`}
                                            >
                                                <AiFillPlusCircle
                                                    className={
                                                        each.qty === each.stock
                                                            ? 'cursor-not-allowed'
                                                            : 'cursor-pointer'
                                                    }
                                                    onClick={() => handleIncreaseQty(each.id)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            {/* empty state cart */}
                            {!isFetchingDetailTransaction && cart.length === 0 && (
                                <div className='flex flex-col gap-2 justify-center h-full'>
                                    <div className='text-5xl text-center'>
                                        <FaCartPlus className='mx-auto text-blue-600' />
                                    </div>
                                    <div className='text-sm text-center text-gray-600'>Silahkan tambahkan produk</div>
                                </div>
                            )}
                        </div>
                        <div className='flex flex-col gap-3'>
                            <div className='font-semibold text-lg'>Rincian Transaksi</div>
                            <div className='bg-slate-100 rounded-md p-2 flex flex-col gap-1'>
                                <div className='flex justify-between text-sm'>
                                    <div>Total Produk</div>
                                    <div className='font-semibold'>{cart.length}</div>
                                </div>
                                <div className='flex justify-between text-sm'>
                                    <div>Total Kuantitas</div>
                                    <div className='font-semibold'>{generateTotalItem()}</div>
                                </div>
                                <div className='flex justify-between text-sm font-medium'>
                                    <div>Sub Total</div>
                                    <div className='font-semibold'>Rp {numeral(generateSubTotal()).format()}</div>
                                </div>
                            </div>
                            <Divider variant='dashed' />
                            <div className='flex flex-col gap-2'>
                                <div className='flex gap-1'>
                                    <Button
                                        className={data.isDirectPay ? 'bg-blue-700' : 'bg-white'}
                                        fullWidth
                                        disabled={cart.length === 0}
                                        variant={data.isDirectPay ? 'filled' : 'outline'}
                                        onClick={() => handleDirectPay(true)}
                                    >
                                        Bayar Langsung
                                    </Button>
                                    <Button
                                        className={!data.isDirectPay ? 'bg-blue-700' : 'bg-white'}
                                        fullWidth
                                        disabled={cart.length === 0}
                                        variant={!data.isDirectPay ? 'filled' : 'outline'}
                                        onClick={() => handleDirectPay(false)}
                                    >
                                        Bayar Nanti
                                    </Button>
                                </div>
                                {/* input customer name */}
                                {activeTab === '' && (
                                    <div className='flex justify-between gap-2'>
                                        <div className='text-sm font-medium'>Nama Konsumen</div>
                                        <Input.Wrapper
                                            required
                                            error={
                                                errors?.customer_name?.message
                                                    ? String(errors?.customer_name?.message)
                                                    : null
                                            }
                                        >
                                            <Input
                                                {...register('customer_name', {
                                                    required: {
                                                        value: true,
                                                        message: 'Nama konsumen wajib diisi',
                                                    },
                                                })}
                                                // icon={<div className='text-black text-sm pl-2'>Rp</div>}
                                                placeholder='input nama konsumen'
                                                error={
                                                    errors?.customer_name?.message
                                                        ? String(errors?.customer_name?.message)
                                                        : null
                                                }
                                                size='xs'
                                                className='w-[177px]'
                                            />
                                        </Input.Wrapper>
                                    </div>
                                )}
                                {/* input discount */}
                                <div className='flex justify-between gap-2'>
                                    <div className='text-sm font-medium'>Potongan Harga</div>
                                    <Controller
                                        name='discount'
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Wajib diisi!',
                                            },
                                        }}
                                        render={({ field }) => (
                                            <NumberInput
                                                {...field}
                                                icon={<div className='text-black text-sm pl-2'>Rp</div>}
                                                hideControls
                                                defaultValue={0}
                                                placeholder='0'
                                                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                                                formatter={(value) => {
                                                    const numb =
                                                        value && value[0] === '0' && value.length > 1
                                                            ? value.slice(1)
                                                            : value;

                                                    return numb && !Number.isNaN(parseFloat(numb))
                                                        ? parseFloat(numb).toLocaleString('en')
                                                        : '';
                                                }}
                                                size='xs'
                                                disabled={cart.length === 0}
                                                error={
                                                    errors?.discount?.message ? String(errors?.discount?.message) : null
                                                }
                                            />
                                        )}
                                    />
                                </div>
                                {/* input total payment */}
                                {data.isDirectPay && (
                                    <div className='flex justify-between gap-2'>
                                        <div className='text-sm font-medium'>Total Pembayaran</div>
                                        <Controller
                                            name='total_payment'
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Wajib diisi!',
                                                },
                                                min: {
                                                    value: generateSubTotal() - (watch('discount') ?? 0),
                                                    message: 'Kurang dari total!',
                                                },
                                            }}
                                            render={({ field }) => (
                                                <NumberInput
                                                    {...field}
                                                    icon={<div className='text-black text-sm pl-2'>Rp</div>}
                                                    hideControls
                                                    defaultValue={0}
                                                    placeholder='0'
                                                    parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                                                    formatter={(value) => {
                                                        const numb =
                                                            value && value[0] === '0' && value.length > 1
                                                                ? value.slice(1)
                                                                : value;

                                                        return numb && !Number.isNaN(parseFloat(numb))
                                                            ? parseFloat(numb).toLocaleString('en')
                                                            : '';
                                                    }}
                                                    size='xs'
                                                    disabled={cart.length === 0}
                                                    error={
                                                        errors?.total_payment?.message
                                                            ? String(errors?.total_payment?.message)
                                                            : null
                                                    }
                                                />
                                            )}
                                        />
                                    </div>
                                )}
                            </div>
                            <Divider variant='dashed' />
                            <div className='bg-slate-100 rounded-md p-2 flex flex-col gap-1'>
                                <div className='flex justify-between text-sm font-semibold'>
                                    <div>Total</div>
                                    <div className='flex items-center gap-1'>
                                        Rp {numeral(generateSubTotal() - (watch('discount') ?? 0)).format()}
                                    </div>
                                </div>
                                {data.isDirectPay && (
                                    <div className='flex justify-between text-sm font-semibold'>
                                        <div>Kembali</div>
                                        <div>
                                            Rp{' '}
                                            {numeral(
                                                (watch('total_payment') ?? 0) -
                                                    (generateSubTotal() - (watch('discount') ?? 0))
                                            ).format()}
                                        </div>
                                    </div>
                                )}
                            </div>

                            <Button
                                className='bg-blue-700'
                                fullWidth
                                onClick={handleSubmit(
                                    activeTab === '' ? handleSubmitTransaction : handleUpdaeTransaction
                                )}
                                loading={loading}
                                disabled={cart.length === 0 || isFetchingDetailTransaction}
                            >
                                {activeTab === '' ? 'Proses' : 'Perbaharui'} Transaksi
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>

            {/* modal transaction summary */}
            <Modal
                opened={modal === 'summary'}
                onClose={() => setModal('')}
                radius='lg'
                centered
                withCloseButton={false}
                size={500}
                padding={0}
            >
                <div className='p-6'>
                    <div className='rounded-full w-min bg-blue-100 p-3 ml-auto mr-auto mb-5'>
                        <AiFillCheckCircle className='text-6xl text-blue-700' />
                    </div>
                    <div className='text-center text-lg text-gray-500 mb-2'>Transaksi Berhasil !</div>
                    {/* total transaction */}
                    <div className='text-2xl text-center font-semibold mb-5'>
                        IDR {numeral(generateSubTotal() - 0).format()}
                    </div>
                    <div className='py-5 grid grid-cols-1 gap-3 border-y border-dashed border-gray-300'>
                        {cart.map((each, key) => (
                            <div className='flex gap-3 items-end' key={key}>
                                <Image
                                    width={40}
                                    height={40}
                                    fit='cover'
                                    src={each?.image}
                                    withPlaceholder
                                    placeholder={<TbBaguette className='text-3xl' />}
                                    radius='md'
                                    className='shadow'
                                />
                                <div className='flex flex-col gap-1 grow'>
                                    <div className='text-sm font-medium'>{each?.name}</div>
                                    <div className='text-xs text-gray-500'>
                                        {each?.qty} x Rp {numeral(each?.price).format()}
                                    </div>
                                </div>
                                <div className='text-sm font-medium'>
                                    Rp {numeral(each?.price * each?.qty).format()}
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className='flex flex-col gap-1.5 pt-5'>
                        {/* subtotal */}
                        <div className='flex justify-between text-sm font-medium'>
                            <div>Subtotal</div>
                            <div>Rp {numeral(generateSubTotal()).format()}</div>
                        </div>
                        {/* potongan harga */}
                        <div className='flex justify-between text-sm font-medium'>
                            <div>Potongan Harga</div>
                            <div>- Rp {numeral(watch('discount') ?? 0).format()}</div>
                        </div>
                        {/* total */}
                        <div className='flex justify-between text-sm font-medium'>
                            <div>Total</div>
                            <div>Rp {numeral(generateSubTotal() - (watch('discount') ?? 0)).format()}</div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default Cashier;

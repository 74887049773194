import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { FieldValues, useForm } from 'react-hook-form';
import { merchant, upload } from '../../../api/endpoints';
import api from '../../../api';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { notifications } from '@mantine/notifications';

const defaultValue = {
    image: '',
    name: '',
    address: '',
    description: '',
    phone_number: '',
    type: '',
};

function FormHooks() {
    const navigate = useNavigate();
    const { id } = useParams();
    const { data: detailMerchant = defaultValue } = useQuery(
        ['detail_merchant'],
        async () => {
            const url = generatePath(merchant.findByID, { id });
            const res = await api.get(url);

            if (!res?.data?.status) {
                notifications.show({
                    message: 'Ups, terjadi kesalahan saat fetch detail merchant.',
                    color: 'red',
                });
                return defaultValue;
            }
            return res?.data?.data;
        },
        {
            enabled: id !== undefined,
        }
    );
    const {
        register,
        control,
        watch,
        formState: { errors },
        setValue,
        handleSubmit,
    } = useForm();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setValue('image', id ? detailMerchant.image : defaultValue.image);
        setValue('name', id ? detailMerchant.name : defaultValue.name);
        setValue('address', id ? detailMerchant.address : defaultValue.address);
        setValue('description', id ? detailMerchant.description : defaultValue.description);
        setValue('phone_number', id ? detailMerchant.phone_number : defaultValue.phone_number);
        setValue('type', id ? detailMerchant.type : defaultValue.type);
    }, [detailMerchant, id]);

    const handleUploadImage = async (value: File) => {
        const data = new FormData();
        data.append('image', value);
        const res = await api.post(upload.image, data);

        if (res?.data?.status) {
            setValue('image', res.data.data.url);
        } else {
            notifications.show({
                message: 'Ups, terjadi kesalahan saat upload gambar.',
                color: 'red',
            });
        }
    };

    const onSubmit = async (data: FieldValues) => {
        setLoading(true);
        const url = id ? generatePath(merchant.update, { id }) : merchant.create;
        const res = id ? await api.put(url, data) : await api.post(merchant.create, data);

        if (res.data.status) {
            navigate('/merchant');
        } else {
            notifications.show({
                message: 'Ups, terjadi kesalahan saat submit merchant.',
                color: 'red',
            });
        }
    };

    return {
        register,
        control,
        watch,
        errors,
        handleSubmit,
        loading,
        handleUploadImage,
        onSubmit,
    };
}

export default FormHooks;

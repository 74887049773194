import { MantineProvider, MantineThemeOverride } from '@mantine/core';
import { DatesProvider } from '@mantine/dates';
import { Notifications } from '@mantine/notifications';
import { QueryClientProvider } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import { client } from './lib/client';

const overrideTheme: MantineThemeOverride = {
    // fontFamily: 'Inter',
};

dayjs.locale('id');

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
        <QueryClientProvider client={client}>
            <MantineProvider theme={overrideTheme} withGlobalStyles withNormalizeCSS>
                <DatesProvider settings={{ locale: 'id', firstDayOfWeek: 0, weekendDays: [0] }}>
                    <Notifications position='top-right' />
                    <App />
                </DatesProvider>
            </MantineProvider>
        </QueryClientProvider>
    </React.StrictMode>
);

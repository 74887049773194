import { notifications } from '@mantine/notifications';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { generatePath } from 'react-router-dom';
import api from '../../api';
import { product_category, products, transaction } from '../../api/endpoints';
import { IFetchProductParams } from '../../interface/Product';
import { ICart } from '../../interface/Transaction';

const defaultParams = {
    page: 1,
    per_page: 1000000,
    q: '',
};

function CashierHooks() {
    const [modal, setModal] = useState('');
    const [activeTab, setActiveTab] = useState<string>('');
    const [cart, setCart] = useState<ICart[]>([]);
    const [data, setData] = useState({
        isDirectPay: true,
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [params, setParams] = useState<IFetchProductParams>(defaultParams);
    const {
        control,
        register,
        watch,
        formState: { errors },
        handleSubmit,
        setValue,
    } = useForm({
        defaultValues: {
            customer_name: '',
            discount: 0,
            total_payment: 0,
        },
    });

    // fetching ongoing transaction
    const {
        data: onGoingTransaction,
        isFetching: isFetchingTransaction,
        refetch,
    } = useQuery(
        ['cashier_ongoing_transaction'],
        async () => {
            const res = await api.get(transaction.fetch, {
                params: {
                    merchant_id: localStorage.getItem('merchantID'),
                    page: 1,
                    per_page: 100000,
                    status: 'PROCESSING',
                },
            });

            if (!res?.data?.status) {
                notifications.show({
                    message: 'Ups, terjadi kesalahan saat fetch data transaksi.',
                    color: 'red',
                });
            }
            return res?.data;
        },
        { enabled: modal === 'cashier' }
    );

    // fetching transaction detail
    const { isFetching: isFetchingDetailTransaction } = useQuery(
        ['cashier_transaction_detail', activeTab],
        async () => {
            const url = generatePath(transaction.detail, { code: activeTab });
            const res = await api.get(url);

            if (!res?.data?.status) {
                notifications.show({
                    message: 'Ups, terjadi kesalahan saat fetch detail merchant.',
                    color: 'red',
                });
            }
            if (res?.data?.status) {
                setCart(
                    res?.data?.data?.items?.map((datum: any) => {
                        return {
                            id: datum.product_id,
                            image: datum.product_image,
                            name: datum.product_name,
                            price: datum.price,
                            qty: datum.qty,
                            stock: datum?.product_stock,
                        };
                    })
                );
            }

            return res?.data;
        },
        { enabled: modal === 'cashier' && activeTab !== '' }
    );

    // function for fetching product categories
    const { data: productCategory = [] } = useQuery(
        ['product_category_cashier', localStorage.getItem('merchantID')],
        async () => {
            const res = await api.get(product_category.fetch, {
                params: {
                    merchant_id: localStorage.getItem('merchantID'),
                },
            });

            if (!res?.data?.status) {
                notifications.show({
                    message: 'Ups, terjadi kesalahan saat fetch kategori produk.',
                    color: 'red',
                });
            }

            return res?.data?.data;
        },
        { enabled: modal === 'cashier' }
    );

    // function for fetching list products
    const { data: productData, isFetching: isFetchingProduct } = useQuery(
        ['product_list_cashier', params, localStorage.getItem('merchantID')],
        async () => {
            const res = await api.get(products.fetch, {
                params: {
                    merchant_id: localStorage.getItem('merchantID'),
                    ...params,
                },
            });

            if (!res?.data?.status) {
                notifications.show({
                    message: 'Ups, terjadi kesalahan saat fetch data produk.',
                    color: 'red',
                });
            }
            return res?.data;
        },
        { enabled: modal === 'cashier' }
    );

    useEffect(() => {
        modal === '' && setCart([]);
        modal === '' && setParams(defaultParams);
        modal === '' && setData({ ...data, isDirectPay: true });
        modal === '' && setValue('discount', 0);
        modal === '' && setValue('total_payment', 0);
        modal === '' && setActiveTab('');
    }, [modal]);

    // function for handle change tab
    const handleChangeTab = (tab: string) => {
        setActiveTab(tab);
        tab === '' && setCart([]);
    };

    // function for handle add product into cart
    const handleAddToCart = (product: ICart) => {
        setCart([...cart, ...[product]]);
    };

    // function for handle increase qty product on cart
    const handleIncreaseQty = (id: number) => {
        const data = cart;
        const changeIndex = data.findIndex((datum) => datum.id === id);

        if (data[changeIndex].qty !== data[changeIndex].stock) {
            data[changeIndex].qty += 1;
            setCart([...data]);
        }
    };

    // function for handle decrease qty product on cart
    const handleDecreaseQty = (id: number) => {
        const data = cart;
        const changeIndex = data.findIndex((datum) => datum.id === id);

        if (data[changeIndex].qty === 1) {
            const newData = data.filter((datum) => datum.id !== id);
            setCart(newData);
        } else {
            data[changeIndex].qty -= 1;
            setCart([...data]);
        }
    };

    // function for generate total selected items
    const generateTotalItem = () => {
        let totalItem = 0;
        for (let i = 0; i < cart.length; i++) {
            totalItem += cart[i].qty;
        }

        return totalItem;
    };

    // fuction for generate sub total price selected items
    const generateSubTotal = () => {
        let subTotal = 0;
        for (let i = 0; i < cart.length; i++) {
            subTotal += cart[i].price * cart[i].qty;
        }

        return subTotal;
    };

    // function for handle change direct pay
    const handleDirectPay = (value: boolean) => {
        setData({
            ...data,
            isDirectPay: value,
        });
        setValue('total_payment', 0);
    };

    // handle create new transaction
    const handleSubmitTransaction = async (data: FieldValues) => {
        const payload = {
            merchant_id: parseInt(localStorage.getItem('merchantID') || '0'),
            discount: data?.discount,
            total_payment: data?.total_payment,
            items: cart.map((each) => {
                return { product_id: each.id, qty: each.qty };
            }),
            customer_name: data?.customer_name,
        };

        setLoading(true);
        const res = await api.post(transaction.create, payload);

        setLoading(false);
        if (res?.data?.status) {
            setModal('summary');
        } else {
            notifications.show({
                message: 'Ups, terjadi kesalahan saat membuat transaksi.',
                color: 'red',
            });
        }

        setModal('summary');
    };

    // handle update transaction
    const handleUpdaeTransaction = async (data: FieldValues) => {
        const payload = {
            transaction_code: activeTab,
            discount: data.discount,
            total_payment: data.total_payment,
            items: cart.map((each) => {
                return { product_id: each.id, qty: each.qty };
            }),
        };

        setLoading(true);
        const res = await api.put(transaction.update_item, payload);

        setLoading(false);
        if (res?.data?.status) {
            setModal('summary');
        } else {
            notifications.show({
                message: 'Ups, terjadi kesalahan saat membuat transaksi.',
                color: 'red',
            });
        }

        setModal('summary');
    };

    return {
        defaultParams,
        activeTab,
        modal,
        setModal,
        cart,
        data,
        loading,
        params,
        setParams,
        watch,
        handleSubmit,
        errors,
        control,
        register,
        onGoingTransaction,
        isFetchingTransaction,
        refetch,
        isFetchingDetailTransaction,
        productCategory,
        productData,
        isFetchingProduct,
        handleChangeTab,
        handleAddToCart,
        handleIncreaseQty,
        handleDecreaseQty,
        generateTotalItem,
        generateSubTotal,
        handleDirectPay,
        handleSubmitTransaction,
        handleUpdaeTransaction,
    };
}

export default CashierHooks;

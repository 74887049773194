import { Badge, Breadcrumbs, Image, Loader, Select, Skeleton, Table } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import dayjs from 'dayjs';
import numeral from 'numeral';
import Chart from 'react-apexcharts';
import { BsFillBox2HeartFill } from 'react-icons/bs';
import { FaCartPlus } from 'react-icons/fa';
import { TbBaguette } from 'react-icons/tb';
import { Link } from 'react-router-dom';
import { ILowStockProduct } from '../../interface/Report';
import { IListTransaction } from '../../interface/Transaction';
import DashboardHooks from './DashboardHooks';

const Dashboard = () => {
    const {
        firstday,
        lastday,
        params,
        summaryParams,
        setSummaryParams,
        transactionReport,
        isFetchingTransactionReport,
        transactionReportSummary,
        onGoingTransaction,
        isFetchingTransaction,
        handleFilterDate,
        generateYearOption,
    } = DashboardHooks();

    return (
        <div className='flex flex-col'>
            <div className='flex justify-between'>
                <div className='text-xl font-semibold py-4'>Dashboard</div>
                <Breadcrumbs className='text-sm text-gray-600'>
                    <div>App</div>
                    <div>Dashboard</div>
                </Breadcrumbs>
            </div>
            <div className='flex flex-col gap-3'>
                <div className='flex justify-end bg-white shadow-md rounded-md p-4'>
                    <DatePickerInput
                        type='range'
                        valueFormat='D MMMM YYYY'
                        placeholder='Tanggal Awal - Tanggal Akhir'
                        onChange={(value) => handleFilterDate(value)}
                        miw={250}
                        className='bg-white border-0'
                        value={[
                            new Date(params?.start_date ?? new Date()),
                            params?.end_date ? new Date(params?.end_date) : null,
                        ]}
                        clearable={params?.start_date !== firstday || params?.end_date !== lastday}
                    />
                </div>
                <div className='grid grid-cols-3 gap-3'>
                    {/* total produk terjual */}
                    <div className=' bg-white p-5 shadow-md rounded-md text-sm flex items-end'>
                        <div className='flex flex-col gap-2'>
                            <div className='text-2xl font-bold'>
                                {/* loading */}
                                {isFetchingTransactionReport && <Loader size='sm' />}

                                {/* showing data */}
                                {!isFetchingTransactionReport && (transactionReport?.data?.items_sold ?? 0)}
                            </div>
                            <div className='font-semibold'>Total Item Terjual</div>
                        </div>
                    </div>
                    {/* total pendapatan */}
                    <div className='bg-white p-5 shadow-md rounded-md text-sm flex items-end'>
                        <div className='flex flex-col gap-2'>
                            <div className='text-2xl font-bold'>
                                {/* loading */}
                                {isFetchingTransactionReport && <Loader size='sm' />}

                                {/* showing data */}
                                {!isFetchingTransactionReport && (
                                    <span>Rp {numeral(transactionReport?.data?.sales_amount ?? 0).format()}</span>
                                )}
                            </div>
                            <div className='font-semibold'>Total Pendapatan</div>
                        </div>
                    </div>
                    {/* penjualan terbaik */}
                    <div className='bg-white p-5 shadow-md rounded-md text-sm flex items-end'>
                        <div className='flex flex-col gap-2 grow'>
                            <div className='flex flex-col gap-1.5'>
                                {/* loading */}
                                {isFetchingTransactionReport && <Loader size='sm' />}

                                {/* empty state */}
                                {!isFetchingTransactionReport && !transactionReport?.data?.best_selling_item && (
                                    <div className='flex flex-col gap-1.5 py-3'>
                                        <FaCartPlus className='text-4xl mx-auto text-blue-600' />
                                        <div className='text-center text-xs text-gray-500'>
                                            Segera lakukan transaksi. <br />
                                            Untuk mengetahui produk terbaik.
                                        </div>
                                    </div>
                                )}

                                {/* showing data */}
                                {!isFetchingTransactionReport && transactionReport?.data?.best_selling_item && (
                                    <div className='flex gap-2'>
                                        <Image
                                            width={45}
                                            height={45}
                                            fit='cover'
                                            src={transactionReport?.data?.best_selling_item?.image}
                                            withPlaceholder
                                            placeholder={<TbBaguette className='text-3xl' />}
                                            className='shadow'
                                            radius='lg'
                                        />
                                        <div className='flex flex-col gap-0.5'>
                                            <div className='font-semibold'>
                                                {transactionReport?.data?.best_selling_item?.name}
                                            </div>
                                            <div className='text-xs'>
                                                Terjual {transactionReport?.data?.best_selling_item?.total_sold ?? 0}{' '}
                                                item
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className='font-semibold'>Penjualan Terbaik</div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* statistik pendapatan */}
                <div className='grid grid-cols-3 gap-3'>
                    <div className='col-span-2'>
                        <div className='bg-white rounded-md shadow-md p-5 h-[calc(100vh-550px)]'>
                            <div className='flex justify-between items-center'>
                                <div className='font-semibold text-sm'>Statistik Pendapatan</div>
                                <Select
                                    maw={100}
                                    placeholder='2023'
                                    data={generateYearOption()}
                                    size='sm'
                                    value={summaryParams}
                                    onChange={(value) => setSummaryParams(value ?? String(dayjs().year()))}
                                />
                            </div>
                            <Chart
                                options={{
                                    xaxis: {
                                        categories: [
                                            'Januari',
                                            'Februari',
                                            'Maret',
                                            'April',
                                            'Mei',
                                            'Juni',
                                            'Juli',
                                            'Agustus',
                                            'September',
                                            'Oktober',
                                            'November',
                                            'Desember',
                                        ],
                                        labels: {
                                            style: {
                                                fontWeight: 500,
                                            },
                                        },
                                    },
                                    colors: ['#1D4ED8'],
                                    dataLabels: {
                                        enabled: false,
                                    },
                                    grid: {
                                        show: true,
                                    },
                                    plotOptions: {
                                        bar: {
                                            horizontal: true,
                                        },
                                    },
                                    chart: {
                                        toolbar: {
                                            show: false,
                                        },
                                        stacked: true,
                                    },
                                }}
                                series={[
                                    {
                                        name: 'Total Pendapatan',
                                        data: [
                                            transactionReportSummary?.data?.January?.total_price,
                                            transactionReportSummary?.data?.February?.total_price,
                                            transactionReportSummary?.data?.March?.total_price,
                                            transactionReportSummary?.data?.April?.total_price,
                                            transactionReportSummary?.data?.May?.total_price,
                                            transactionReportSummary?.data?.June?.total_price,
                                            transactionReportSummary?.data?.July?.total_price,
                                            transactionReportSummary?.data?.August?.total_price,
                                            transactionReportSummary?.data?.September?.total_price,
                                            transactionReportSummary?.data?.October?.total_price,
                                            transactionReportSummary?.data?.November?.total_price,
                                            transactionReportSummary?.data?.December?.total_price,
                                        ],
                                    },
                                ]}
                                type='bar'
                                height='90%'
                            />
                        </div>
                    </div>
                    {/* produk segera habis */}
                    <div className='bg-white p-5 shadow-md rounded-md text-sm max-h-[489px] overflow-auto'>
                        <div className='flex flex-col gap-2'>
                            <div>
                                <div className='font-semibold'>Produk Segera Habis</div>
                            </div>
                            <div className='flex flex-col gap-2'>
                                {/* loading */}
                                {isFetchingTransactionReport && <Loader size='sm' />}

                                {/* empty state */}
                                {!isFetchingTransactionReport && transactionReport?.data?.low_stock?.length === 0 && (
                                    <div className='flex flex-col gap-2 py-5'>
                                        <BsFillBox2HeartFill className='text-4xl mx-auto text-blue-600' />
                                        <div className='text-center text-xs text-gray-500'>
                                            Tenang, stock produk mu aman. <br />
                                            Lakukan pengecekan secara berkala ya.
                                        </div>
                                    </div>
                                )}

                                {/* showing data */}
                                {!isFetchingTransactionReport &&
                                    transactionReport?.data?.low_stock?.map((datum: ILowStockProduct, key: number) => (
                                        <div className='flex gap-3 px-1.5 py-2.5 shadow rounded' key={key}>
                                            <Image
                                                width={40}
                                                height={40}
                                                fit='cover'
                                                src={datum?.image}
                                                withPlaceholder
                                                placeholder={<TbBaguette className='text-3xl' />}
                                                className='shadow'
                                                radius='lg'
                                            />
                                            <div className='flex flex-col gap-1'>
                                                <div className='font-semibold'>{datum?.name}</div>
                                                <div className='flex gap-2 items-center'>
                                                    <div className='text-xs'>{datum?.stock}</div>
                                                    {datum?.stock === 0 && (
                                                        <Badge color='red' size='sm'>
                                                            Habis
                                                        </Badge>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>

                {/* transaksi berjalan */}
                <div className='bg-white p-5 shadow-md rounded-md flex flex-col gap-3 max-h-[500px] overflow-auto'>
                    <div className='font-semibold text-sm'>Transaksi Berjalan</div>
                    <Table verticalSpacing='md' horizontalSpacing='md'>
                        <thead>
                            <tr className='bg-blue-50'>
                                <th>Kode Transaksi</th>
                                <th className='!text-center'>Total Item</th>
                                <th className='!text-right'>Total Harga</th>
                                <th className='!text-center'>Tanggal Transaksi</th>
                                <th className='!text-center'>Aksi</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* loading fetching data */}
                            {isFetchingTransaction && (
                                <tr className='mb-2 rounded-table-cell'>
                                    <td colSpan={5} className='rounded-lg'>
                                        <Skeleton height={54} radius='md' />
                                    </td>
                                </tr>
                            )}

                            {/* list data */}
                            {!isFetchingTransaction &&
                                onGoingTransaction.data.length > 0 &&
                                onGoingTransaction.data.map((datum: IListTransaction, key: number) => (
                                    <tr className='[&>td]:align-top' key={key}>
                                        <td>
                                            <div className='mb-1 font-semibold'>{datum?.code}</div>
                                            <div className='text-xs text-gray-500'>
                                                dibuat oleh {datum?.created_by?.name ?? '-'}
                                            </div>
                                        </td>
                                        <td className='text-center'>{datum?.total_item}</td>
                                        <td className='text-right font-semibold'>
                                            Rp {numeral(datum?.total_price).format()}
                                        </td>
                                        <td className='text-center'>
                                            {dayjs(datum?.created_at).format('D MMMM YYYY, HH:mm')}
                                        </td>
                                        <td className='text-center'>
                                            <Link
                                                to={`/app/transaction/detail/${datum?.code}`}
                                                className='text-blue-700'
                                            >
                                                Lihat Detail
                                            </Link>
                                        </td>
                                    </tr>
                                ))}

                            {/* empty state */}
                            {!isFetchingTransaction && onGoingTransaction.data.length === 0 && (
                                <tr>
                                    <td colSpan={6}>
                                        <img
                                            src='/empty_illustation.svg'
                                            alt='empty data'
                                            className='ml-auto mr-auto'
                                        />
                                        <div className='text-center font-medium text-lg'>Belum memiliki transaksi</div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>

                {/* for giving space on the bottom of the page */}
                <div></div>
            </div>
        </div>
    );
};

export default Dashboard;

import { ReactNode } from 'react';
import packageJson from '../../../package.json';

type Props = {
    title: string;
    children: ReactNode;
};

const AuthLayout: React.FC<Props> = (props) => {
    const { title, children } = props;

    return (
        <div className='h-screen grid grid-cols-1 justify-items-center content-center bg-gray-100 relative'>
            <img src='/illus_auth.svg' alt='authentication illustration' className='w-full h-auto max-h-screen' />
            <div className='absolute top-0 right-0 bottom-0 left-0 flex items-center justify-center opacity-95'>
                <div className='px-6 py-8 bg-white rounded-xl shadow-2xl'>
                    <div className='text-2xl font-semibold text-center mb-7'>{title}</div>
                    <div>{children}</div>
                    <div className='text-sm text-gray-400 text-center pt-5'>Web version {packageJson.version}</div>
                </div>
            </div>
        </div>
    );
};

export default AuthLayout;

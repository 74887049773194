import { useQuery } from '@tanstack/react-query';
import api from '../../../api';
import { useState } from 'react';
import { product_category } from '../../../api/endpoints';
import { notifications } from '@mantine/notifications';

function CategoryHooks() {
    const { data: productCategory = [], refetch } = useQuery(['product_category'], async () => {
        const res = await api.get(product_category.fetch, {
            params: {
                merchant_id: localStorage.getItem('merchantID'),
            },
        });

        if (!res?.data?.status) {
            notifications.show({
                message: 'Ups, terjadi kesalahan saat fetch kategori produk.',
                color: 'red',
            });
        }

        return res?.data?.data;
    });
    const [modal, setModal] = useState<boolean>(false);

    return {
        productCategory,
        refetch,
        modal,
        setModal,
    };
}

export default CategoryHooks;

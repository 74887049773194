import { AiOutlineBarChart, AiOutlineUserSwitch } from 'react-icons/ai';
import { BiBox, BiMoneyWithdraw } from 'react-icons/bi';
// import { IoStorefrontOutline } from 'react-icons/io5';
import { Image, Menu } from '@mantine/core';
import { useState } from 'react';
import { FaChevronRight } from 'react-icons/fa';
import { HiOutlineBuildingStorefront } from 'react-icons/hi2';
import { IoStorefrontOutline } from 'react-icons/io5';
import { LuLogOut } from 'react-icons/lu';
import { RxHamburgerMenu } from 'react-icons/rx';
import { useLocation } from 'react-router-dom';
import packageJson from '../../../../package.json';
import { MenuItem } from '../../../components';
import SideMenuContext from './SideMenuContext';

const SideMenu = () => {
    const { pathname } = useLocation();
    const [minimize, setMinimize] = useState<boolean>(false);
    const { detailMerchant, handleChooseMerchant, handleLogout } = SideMenuContext();
    const menu = [
        { icon: <AiOutlineBarChart />, label: 'Dashboard', route: '/app/dashboard', isShowing: true },
        { icon: <BiBox />, label: 'Produk', route: '/app/product', isShowing: true },
        { icon: <BiMoneyWithdraw />, label: 'Transaksi', route: '/app/transaction', isShowing: true },
        {
            icon: <AiOutlineUserSwitch />,
            label: 'Karyawan',
            route: '/app/user',
            isShowing: localStorage.getItem('role') === 'owner' ? true : false,
        },
    ];

    return (
        <nav
            className={`bg-white h-full rounded-md shadow-md p-4 ${
                minimize ? 'w-[82px]' : 'w-[250px]'
            } transition-all duration-200 ease-in-out`}
        >
            <div className='border-b pb-4 h-[48px] flex items-center text-xl justify-between'>
                {minimize ? (
                    <div className='p-3'>
                        <RxHamburgerMenu className='cursor-pointer text-2xl' onClick={() => setMinimize(false)} />
                    </div>
                ) : (
                    <>
                        <div className='w-[194px] font-medium text-blue-700 '>
                            kasirin <span className='font-bold'>| POS</span>
                        </div>
                        <div>{/* <GrFormClose className='cursor-pointer' onClick={() => setMinimize(true)} /> */}</div>
                    </>
                )}
            </div>
            <div className='pt-4 flex flex-col gap-2 h-[calc(100vh-260px)] overflow-auto'>
                {menu.map((each, key) => (
                    <MenuItem
                        key={key}
                        icon={each.icon}
                        label={each.label}
                        isMinimize={minimize}
                        to={each.route}
                        isActive={pathname.includes(each.route)}
                        isShowing={each?.isShowing}
                    />
                ))}
            </div>

            <div className='flex justify-center pt-2'>
                <div className='flex flex-col gap-2 w-fit'>
                    <Image
                        width={70}
                        height={70}
                        fit='cover'
                        src={detailMerchant?.image}
                        withPlaceholder
                        placeholder={<HiOutlineBuildingStorefront className='text-3xl' />}
                        radius='md'
                        className='mx-auto'
                    />
                    <div>
                        <Menu width={200} position='right-end'>
                            <Menu.Target>
                                <div className='flex gap-1 items-center justify-center cursor-pointer'>
                                    <div className='font-semibold text-sm'>{detailMerchant?.name}</div>
                                    <FaChevronRight className='text-xs' />
                                </div>
                            </Menu.Target>

                            <Menu.Dropdown className='p-1 shadow-md'>
                                <Menu.Label className='hover:bg-gray-50'>
                                    <div
                                        className='text-sm cursor-pointer flex gap-2 items-center text-gray-600'
                                        onClick={handleChooseMerchant}
                                    >
                                        <IoStorefrontOutline /> Toko
                                    </div>
                                </Menu.Label>
                                <Menu.Label className='hover:bg-gray-50'>
                                    <div
                                        className='text-sm cursor-pointer text-red-600 flex gap-2 items-center'
                                        onClick={handleLogout}
                                    >
                                        <LuLogOut /> Keluar
                                    </div>
                                </Menu.Label>
                            </Menu.Dropdown>
                        </Menu>
                        <div className='text-xs text-gray-500 text-center'>{localStorage.getItem('username')}</div>
                    </div>
                </div>
            </div>

            {!minimize && (
                <div className='text-sm text-gray-400 py-3 text-center'>Web version {packageJson.version}</div>
            )}
        </nav>
    );
};

export default SideMenu;

import { notifications } from '@mantine/notifications';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../../../api';
import { transaction } from '../../../../api/endpoints';

function CancelHooks(refetch: () => void) {
    const { code } = useParams();
    const [modal, setModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    // function for handle cancel transaction
    const handleCancelTransaction = async () => {
        const payload = {
            transaction_code: code,
        };
        console.log(payload);

        setLoading(true);
        const res = await api.post(transaction.cancel, payload);

        setLoading(false);
        if (res?.data?.status) {
            notifications.show({
                message: 'Transaksi berhasil dibatalkan.',
                color: 'blue',
            });
            setModal(false);
            refetch();
        } else {
            notifications.show({
                message: 'Ups, terjadi kesalahan saat membuat transaksi.',
                color: 'red',
            });
        }
    };

    return {
        code,
        modal,
        setModal,
        loading,
        handleCancelTransaction,
    };
}

export default CancelHooks;

import { Button, Divider, Image, Modal, NumberInput } from '@mantine/core';
import numeral from 'numeral';
import { Controller } from 'react-hook-form';
import { TbBaguette } from 'react-icons/tb';
import AcceptHooks from './AcceptHooks';

type Props = {
    products: Array<any>;
    total_qty: number;
    sub_total: number;
    discount: number;
    refetch: () => void;
};

const Accept: React.FC<Props> = (props) => {
    const { products, total_qty, sub_total, discount, refetch } = props;
    const { handleSubmit, control, errors, modal, setModal, loading, generateTotal, generateReturn, handleAccept } =
        AcceptHooks(sub_total, discount, refetch);

    return (
        <div>
            <Button className='bg-blue-700' onClick={() => setModal(true)}>
                Selesai
            </Button>

            <Modal
                opened={modal}
                onClose={() => setModal(false)}
                size={1100}
                withCloseButton={false}
                padding={0}
                radius='lg'
                centered
            >
                <div className='grid grid-cols-3'>
                    {/* list order */}
                    <div className='col-span-2 bg-gray-50'>
                        <div className='flex justify-between items-end px-6 pt-6 pb-3'>
                            <div className='font-semibold text-xl'>List Order</div>
                            <div className='text-sm text-gray-500'>Total {products?.length} produk</div>
                        </div>
                        <div className='grid grid-cols-3 gap-4 max-h-[535px] overflow-auto px-6 pb-6 pt-0.5'>
                            {products?.map((datum, key: number) => (
                                <div key={key} className='bg-white p-3 rounded-md flex flex-col gap-2 shadow'>
                                    <Image
                                        width='100%'
                                        height={150}
                                        fit='cover'
                                        src={datum?.product_image}
                                        withPlaceholder
                                        placeholder={<TbBaguette className='text-3xl' />}
                                        radius='md'
                                        className='shadow-md'
                                        mx='auto'
                                    />
                                    <div className='flex flex-col gap-0.5'>
                                        <div className='font-semibold'>{datum?.product_name}</div>
                                        <div className='text-sm'>Harga: Rp {numeral(datum?.price).format()}</div>
                                        <div className='text-sm'>Kuantitas: {numeral(datum?.qty).format()}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* detail transaction */}
                    <div className='p-6 flex flex-col gap-4 relative'>
                        <div className='font-semibold text-xl'>Detail Transaksi</div>
                        <div className='bg-blue-50 py-3 px-2 flex flex-col gap-1 rounded-md'>
                            {/* total kuantitas */}
                            <div className='flex justify-between gap-2 text-sm'>
                                <div>Total Kuantitas</div>
                                <div>{numeral(total_qty).format()}</div>
                            </div>
                            {/* sub total */}
                            <div className='flex justify-between gap-2 text-sm'>
                                <div>Sub Total</div>
                                <div>Rp {numeral(sub_total).format()}</div>
                            </div>
                        </div>

                        <Divider variant='dashed' />

                        <div className='flex flex-col gap-2'>
                            {/* input discount */}
                            <div className='flex justify-between gap-2'>
                                <div className='text-sm font-medium'>Potongan Harga</div>
                                <Controller
                                    name='discount'
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Wajib diisi!',
                                        },
                                    }}
                                    render={({ field }) => (
                                        <NumberInput
                                            {...field}
                                            icon={<div className='text-black text-sm pl-2'>Rp</div>}
                                            hideControls
                                            defaultValue={0}
                                            placeholder='0'
                                            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                                            formatter={(value) => {
                                                const numb =
                                                    value && value[0] === '0' && value.length > 1
                                                        ? value.slice(1)
                                                        : value;

                                                return numb && !Number.isNaN(parseFloat(numb))
                                                    ? parseFloat(numb).toLocaleString('en')
                                                    : '';
                                            }}
                                            size='xs'
                                            error={errors?.discount?.message ? String(errors?.discount?.message) : null}
                                        />
                                    )}
                                />
                            </div>
                            {/* input total payment */}
                            <div className='flex justify-between gap-2'>
                                <div className='text-sm font-medium'>Total Pembayaran</div>
                                <Controller
                                    name='total_payment'
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Wajib diisi!',
                                        },
                                        min: {
                                            value: generateTotal(),
                                            message: 'Kurang dari total!',
                                        },
                                    }}
                                    render={({ field }) => (
                                        <NumberInput
                                            {...field}
                                            icon={<div className='text-black text-sm pl-2'>Rp</div>}
                                            hideControls
                                            defaultValue={0}
                                            placeholder='0'
                                            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                                            formatter={(value) => {
                                                const numb =
                                                    value && value[0] === '0' && value.length > 1
                                                        ? value.slice(1)
                                                        : value;

                                                return numb && !Number.isNaN(parseFloat(numb))
                                                    ? parseFloat(numb).toLocaleString('en')
                                                    : '';
                                            }}
                                            size='xs'
                                            error={
                                                errors?.total_payment?.message
                                                    ? String(errors?.total_payment?.message)
                                                    : null
                                            }
                                        />
                                    )}
                                />
                            </div>
                        </div>

                        <Divider variant='dashed' />

                        <div className='bg-blue-50 py-3 px-2 flex flex-col gap-1 rounded-md'>
                            <div className='flex justify-between text-sm font-semibold'>
                                <div>Total</div>
                                <div className='flex items-center gap-1'>Rp {numeral(generateTotal()).format()}</div>
                            </div>
                            <div className='flex justify-between text-sm font-semibold'>
                                <div>Kembali</div>
                                <div>Rp {numeral(generateReturn()).format()}</div>
                            </div>
                        </div>

                        <Button
                            className='bg-blue-700'
                            fullWidth
                            onClick={handleSubmit(handleAccept)}
                            loading={loading}
                            // disabled={cart.length === 0}
                        >
                            Selesaikan Transaksi
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default Accept;

import { Button, Modal } from '@mantine/core';
import { GrFormClose } from 'react-icons/gr';
import CreateHooks from './CreateHooks';
import Form from '../form';

type Props = {
    refetch: () => void;
};

const Create: React.FC<Props> = (props) => {
    const { refetch } = props;
    const { modal, setModal } = CreateHooks();

    return (
        <>
            <Button className='bg-blue-700' fullWidth onClick={() => setModal(true)}>
                Tambah Kategori
            </Button>

            <Modal
                opened={modal}
                onClose={() => setModal(false)}
                withCloseButton={false}
                padding={0}
                radius='md'
                size={350}
            >
                <div className='flex justify-between items-center px-5 py-4 border-b'>
                    <div className='text-lg font-semibold'>Tambah Kategori</div>
                    <GrFormClose className='cursor-pointer' onClick={() => setModal(false)} />
                </div>
                <Form refetch={refetch} setModal={setModal} />
            </Modal>
        </>
    );
};

export default Create;

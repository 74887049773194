import { Badge, Breadcrumbs, Skeleton, Table } from '@mantine/core';
import { IListEmployee } from '../../interface/Employee';
import UserHooks from './UserHooks';
import Create from './create';
import Delete from './delete';

const User = () => {
    const { user, isFetching, refetch } = UserHooks();

    return (
        <div className='flex flex-col'>
            <div className='flex justify-between'>
                <div className='text-xl font-semibold py-4'>Karyawan</div>
                <Breadcrumbs className='text-sm text-gray-600'>
                    <div>App</div>
                    <div>Karyawan</div>
                </Breadcrumbs>
            </div>
            <div className='flex flex-col gap-2'>
                <div className='flex justify-end bg-white shadow-md rounded-md p-4'>
                    <Create refetch={refetch} />
                </div>
                <Table
                    verticalSpacing='md'
                    horizontalSpacing='lg'
                    className='border-separate border-spacing-x-0 border-spacing-y-2 [&>thead>tr>th]:border-b-0'
                >
                    <thead>
                        <tr className='bg-white shadow-md'>
                            <th className='rounded-l-lg'>Nama</th>
                            <th>Email</th>
                            <th className='!text-center rounded-r-lg'>Aksi</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* loading fetching data */}
                        {isFetching && (
                            <tr className='bg-white mb-2 rounded-table-cell shadow-md'>
                                <td colSpan={3} className='rounded-lg'>
                                    <Skeleton height={54} radius='md' />
                                </td>
                            </tr>
                        )}

                        {/* list data */}
                        {!isFetching &&
                            user?.data?.length > 0 &&
                            user?.data?.map((datum: IListEmployee, key: number) => (
                                <tr className='shadow-md bg-white [&>td]:align-top' key={key}>
                                    <td className='rounded-l-lg'>
                                        {datum?.user_name} <Badge>{datum?.user_role}</Badge>
                                    </td>
                                    <td>{datum?.user_email}</td>
                                    <td className='rounded-r-lg text-center'>
                                        <div className='flex gap-2 justify-center'>
                                            {/* <BiPencil className='text-lg text-blue-700 cursor-pointer' /> */}
                                            <Delete id={datum?.user_id} name={datum?.user_name} refetch={refetch} />
                                        </div>
                                    </td>
                                </tr>
                            ))}

                        {/* empty state */}
                        {!isFetching && user?.data?.length === 0 && (
                            <tr>
                                <td colSpan={3} className='bg-white rounded-lg shadow-md'>
                                    <img src='/empty_illustation.svg' alt='empty data' className='ml-auto mr-auto' />
                                    <div className='text-center font-medium text-lg'>
                                        Belum memiliki karyawan yang terdaftar
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
        </div>
    );
};

export default User;

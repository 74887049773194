import { notifications } from '@mantine/notifications';
import { useQuery } from '@tanstack/react-query';
import { generatePath, useParams } from 'react-router-dom';
import api from '../../../api';
import { transaction } from '../../../api/endpoints';

function DetailHooks() {
    const { code } = useParams();

    // fetching transaction detail
    const {
        data: transactionDetail,
        isFetching,
        refetch,
    } = useQuery(['transaction_detail', code], async () => {
        const url = generatePath(transaction.detail, { code });
        const res = await api.get(url);

        if (!res?.data?.status) {
            notifications.show({
                message: 'Ups, terjadi kesalahan saat fetch detail merchant.',
                color: 'red',
            });
        }
        return res?.data;
    });

    return {
        code,
        transactionDetail,
        isFetching,
        refetch,
    };
}

export default DetailHooks;

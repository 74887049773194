import { Alert, Button, Input, NumberInput, Select, Textarea } from '@mantine/core';
import { UploadImage } from '../../../components';
import FormHooks from './FormHooks';
import { IProduct } from '../../../interface/Product';
import { Controller } from 'react-hook-form';
import { IoMdAlert } from 'react-icons/io';

type Props = {
    data?: IProduct;
    refetch: () => void;
    handleClose: () => void;
};

const Form: React.FC<Props> = (props) => {
    const { data, refetch, handleClose } = props;
    const { register, control, errors, watch, handleSubmit, productCategory, loading, handleUploadImage, onSubmit } =
        FormHooks(data, refetch, handleClose);

    return (
        <form className='flex flex-col gap-2' onSubmit={handleSubmit(onSubmit)}>
            <div className='flex justify-center'>
                <UploadImage
                    src={watch('image')}
                    accept='image/jpg,image/jpeg,image/png'
                    handleChange={handleUploadImage}
                />
            </div>
            <Input.Wrapper
                label='Nama Produk'
                required
                error={errors?.name?.message ? String(errors?.name?.message) : null}
            >
                <Input
                    {...register('name', {
                        required: {
                            value: true,
                            message: 'Nama produk wajib diisi',
                        },
                    })}
                    placeholder='input nama produk'
                    error={errors?.name?.message ? String(errors?.name?.message) : null}
                />
            </Input.Wrapper>
            <Controller
                name='category_id'
                control={control}
                rules={{
                    required: {
                        value: true,
                        message: 'Kategori produk wajib diisi',
                    },
                }}
                render={({ field }) => (
                    <Select
                        {...field}
                        label='Kategori Produk'
                        placeholder='Pilih kategori produk'
                        data={productCategory}
                        required
                        error={errors?.category_id?.message ? String(errors?.category_id?.message) : null}
                    />
                )}
            />
            <Controller
                name='buying_price'
                control={control}
                rules={{
                    required: {
                        value: true,
                        message: 'Harga beli produk wajib diisi',
                    },
                }}
                render={({ field }) => (
                    <NumberInput
                        {...field}
                        label='Harga Beli Produk'
                        icon={<div className='text-black text-sm pl-2'>Rp</div>}
                        className='mb-1'
                        hideControls
                        placeholder='0'
                        parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                        formatter={(value) =>
                            value && !Number.isNaN(parseFloat(value))
                                ? value?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                : ''
                        }
                        required
                        error={errors?.buying_price?.message ? String(errors?.buying_price?.message) : null}
                    />
                )}
            />
            <Controller
                name='selling_price'
                control={control}
                rules={{
                    required: {
                        value: true,
                        message: 'Harga jual produk wajib diisi',
                    },
                }}
                render={({ field }) => (
                    <NumberInput
                        {...field}
                        label='Harga Jual Produk'
                        icon={<div className='text-black text-sm pl-2'>Rp</div>}
                        className='mb-1'
                        hideControls
                        placeholder='0'
                        parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                        formatter={(value) =>
                            value && !Number.isNaN(parseFloat(value))
                                ? value?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                : ''
                        }
                        required
                        error={errors?.selling_price?.message ? String(errors?.selling_price?.message) : null}
                    />
                )}
            />
            <Controller
                name='stock'
                control={control}
                rules={{
                    required: {
                        value: true,
                        message: 'Stock produk wajib diisi',
                    },
                }}
                render={({ field }) => (
                    <NumberInput
                        {...field}
                        label='Stock'
                        className='mb-1'
                        hideControls
                        placeholder='Input stock produk'
                        parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                        formatter={(value) =>
                            value && !Number.isNaN(parseFloat(value))
                                ? value?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                : ''
                        }
                        required
                        error={errors?.stock?.message ? String(errors?.stock?.message) : null}
                    />
                )}
            />
            <Input.Wrapper label='SKU' required error={errors?.sku?.message ? String(errors?.sku?.message) : null}>
                <Input
                    {...register('sku', {
                        required: {
                            value: true,
                            message: 'SKU wajib diisi',
                        },
                    })}
                    placeholder='input sku produk'
                    error={errors?.name?.message ? String(errors?.name?.message) : null}
                />
            </Input.Wrapper>
            <Input.Wrapper
                label='Deskripsi Produk'
                required
                error={errors?.description?.message ? String(errors?.description?.message) : null}
            >
                <Textarea
                    {...register('description', {
                        required: {
                            value: true,
                            message: 'Deskripsi produk wajib diisi',
                        },
                    })}
                    placeholder='input deskripsi produk'
                    error={errors?.name?.message ? String(errors?.name?.message) : null}
                />
            </Input.Wrapper>

            {!productCategory.length ? (
                <Alert icon={<IoMdAlert />} color='red'>
                    <div className='text-red-600'>Anda belum memiliki kategori produk!</div>
                </Alert>
            ) : null}

            <div className='flex justify-end gap-2 mt-5'>
                <Button variant='outline' color='red' onClick={handleClose}>
                    Batal
                </Button>
                <Button type='submit' className='bg-blue-900' loading={loading} disabled={!productCategory.length}>
                    Submit
                </Button>
            </div>
        </form>
    );
};

export default Form;

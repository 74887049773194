import { Button, Input, PasswordInput, TextInput } from '@mantine/core';
import { Link, Navigate } from 'react-router-dom';
import AuthLayout from '../../layout/authentication';
import { isEmail } from '../../lib/helper';
import LoginHooks from './LoginHooks';

const Login = () => {
    const { register, errors, loading, handleLogin } = LoginHooks();

    if (localStorage.getItem('token') !== null) {
        if (localStorage.getItem('merchantID') === null) {
            return <Navigate to='/merchant' replace />;
        }

        return <Navigate to='/app/dashboard' replace />;
    }

    return (
        <AuthLayout title='Hai, Selamat Datang'>
            <form onSubmit={handleLogin}>
                <div className='flex flex-col gap-3 mb-7 w-[350px]'>
                    <Input.Wrapper
                        label='Email'
                        error={errors?.email?.message ? String(errors?.email?.message) : null}
                        styles={() => ({
                            label: { fontWeight: 400 },
                            error: {
                                visibility: errors?.email ? 'visible' : 'hidden',
                            },
                        })}
                        required
                    >
                        <TextInput
                            {...register('email', {
                                required: {
                                    value: true,
                                    message: 'Email wajib diisi!',
                                },
                                validate: {
                                    notEmail: (value: string) =>
                                        isEmail(value) || 'Email tidak sesuai dengan format. Contoh: name@mail.com',
                                },
                                setValueAs(value: string) {
                                    return value?.toLowerCase();
                                },
                            })}
                            placeholder='Masukan email'
                            error={Boolean(errors?.email)}
                        />
                    </Input.Wrapper>
                    <Input.Wrapper
                        label='Password'
                        error={errors?.password?.message ? String(errors?.password?.message) : null}
                        styles={() => ({
                            label: { fontWeight: 400 },
                            error: {
                                visibility: errors?.email ? 'visible' : 'hidden',
                            },
                        })}
                        required
                    >
                        <PasswordInput
                            {...register('password', {
                                required: {
                                    value: true,
                                    message: 'Password wajib diisi!',
                                },
                            })}
                            placeholder='Masukan password'
                            error={Boolean(errors?.password)}
                        />
                    </Input.Wrapper>
                </div>
                <Button fullWidth className='bg-blue-700' loading={loading} type='submit'>
                    Masuk
                </Button>
            </form>

            <div className='text-sm text-center pt-5'>
                Belum memiliki akun?{' '}
                <Link className='text-blue-700 cursor-pointer hover:underline' to='/register?segment=register'>
                    daftar disini
                </Link>
            </div>
        </AuthLayout>
    );
};

export default Login;

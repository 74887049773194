import { notifications } from '@mantine/notifications';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import api from '../../api';
import { auth } from '../../api/endpoints';

function LoginHooks() {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const handleLogin = handleSubmit(async (data) => {
        setLoading(true);
        const res = await api.post(auth.login, data);

        if (res?.data?.status) {
            localStorage.setItem('token', res?.data?.data?.token);
            localStorage.setItem('username', res?.data?.data?.user_detail?.name);
            localStorage.setItem('role', res?.data?.data?.user_detail?.role);
            navigate('/merchant');
        } else {
            notifications.show({
                message: res?.data?.message,
                color: 'red',
            });
        }
        setLoading(false);
    });

    return {
        register,
        handleSubmit,
        errors,
        loading,
        handleLogin,
    };
}

export default LoginHooks;

import { notifications } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import api from '../../../../api';
import { transaction } from '../../../../api/endpoints';

function AcceptHooks(sub_total: number, discount: number, refetch: () => void) {
    const { code } = useParams();
    const {
        handleSubmit,
        watch,
        control,
        formState: { errors },
        setValue,
    } = useForm({
        defaultValues: {
            discount: discount,
            total_payment: 0,
        },
    });
    const [modal, setModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        modal && setValue('discount', discount);
        modal && setValue('total_payment', 0);
    }, [modal]);

    // function for generate total transaction
    const generateTotal = () => {
        const total = sub_total - watch('discount');
        return total;
    };

    // function for generate return
    const generateReturn = () => {
        const amountReturn =
            watch('total_payment') === 0 ? 0 : watch('total_payment') - (sub_total - watch('discount'));
        return amountReturn;
    };

    // function for handle accept transaction
    const handleAccept = async (data: FieldValues) => {
        const payload = {
            transaction_code: code,
            total_payment: data?.total_payment,
            discount: data?.discount,
        };

        setLoading(true);
        const res = await api.post(transaction.accept, payload);

        setLoading(false);
        if (res?.data?.status) {
            notifications.show({
                message: 'Transaksi selesai!',
                color: 'blue',
            });
            setModal(false);
            refetch();
        } else {
            notifications.show({
                message: 'Ups, terjadi kesalahan saat membuat transaksi.',
                color: 'red',
            });
        }
    };

    return {
        handleSubmit,
        control,
        errors,
        modal,
        setModal,
        loading,
        generateTotal,
        generateReturn,
        handleAccept,
    };
}

export default AcceptHooks;

import { notifications } from '@mantine/notifications';
import { useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import api from '../../../api';
import { user } from '../../../api/endpoints';

function CreateHooks(refetch: () => void) {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        defaultValues: {
            name: '',
            email: '',
            password: '',
        },
    });
    const [modal, setModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    // function for handle create user
    const handleCreateUser = async (data: FieldValues) => {
        const payload = {
            ...data,
            merchant_id: parseInt(localStorage.getItem('merchantID') ?? '0'),
            role: 'admin',
        };

        setLoading(true);
        const res = await api.post(user.create, payload);
        setLoading(false);

        if (res.data.status) {
            refetch();
            reset();
            setModal(false);
        } else {
            notifications.show({
                message: 'Ups, terjadi kesalahan saat membuat karyawan.',
                color: 'red',
            });
        }
    };

    return {
        register,
        handleSubmit,
        errors,
        modal,
        setModal,
        loading,
        handleCreateUser,
    };
}

export default CreateHooks;

import { Badge, Breadcrumbs, Image, Input, Pagination, Select, Skeleton, Table } from '@mantine/core';
import numeral from 'numeral';
import { CiSearch } from 'react-icons/ci';
import { TbBaguette } from 'react-icons/tb';
import { IProduct } from '../../interface/Product';
import ProductsHooks from './ProductsHooks';
import Category from './category';
import Create from './create';
import Delete from './delete';
import Edit from './edit';

const Products = () => {
    const { params, setParams, productData, isFetching, refetch } = ProductsHooks();

    return (
        <div className='flex flex-col'>
            <div className='flex justify-between'>
                <div className='text-xl font-semibold py-4'>Produk</div>
                <Breadcrumbs className='text-sm text-gray-600'>
                    <div>App</div>
                    <div>Produk</div>
                </Breadcrumbs>
            </div>
            <div className='flex flex-col gap-2'>
                <div className='p-4 bg-white rounded-md shadow-md flex justify-between'>
                    <Input
                        placeholder='Cari nama produk'
                        icon={<CiSearch />}
                        className='w-[350px]'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const keyword = e.currentTarget.value;
                            setParams({ ...params, q: keyword });
                        }}
                    />
                    <div className='flex gap-2'>
                        <Category />
                        <Create refetch={refetch} />
                    </div>
                </div>

                <Table
                    verticalSpacing='md'
                    horizontalSpacing='lg'
                    className='border-separate border-spacing-x-0 border-spacing-y-2 [&>thead>tr>th]:border-b-0'
                >
                    <thead>
                        <tr className='bg-white shadow-md'>
                            <th className='rounded-l-lg'>Gambar</th>
                            <th>Nama Produk</th>
                            <th>Harga Beli</th>
                            <th>Harga Jual</th>
                            <th>Stocks</th>
                            <th className='rounded-r-lg'>Aksi</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isFetching ? (
                            <tr className='bg-white mb-2 rounded-table-cell shadow-md'>
                                <td colSpan={6} className='rounded-lg'>
                                    <Skeleton height={82} radius='md' />
                                </td>
                            </tr>
                        ) : productData?.data?.length ? (
                            productData?.data?.map((datum: IProduct, key: number) => (
                                <tr className='shadow-md bg-white [&>td]:align-top' key={key}>
                                    <td className='rounded-l-lg'>
                                        <Image
                                            width={50}
                                            height={50}
                                            fit='cover'
                                            src={datum.image}
                                            withPlaceholder
                                            placeholder={<TbBaguette className='text-3xl' />}
                                            radius='md'
                                        />
                                    </td>
                                    <td>
                                        <div className='mb-1'>{datum?.name}</div>
                                        <Badge>{datum?.category?.name}</Badge>
                                    </td>
                                    <td>Rp {numeral(datum?.buying_price).format()}</td>
                                    <td>Rp {numeral(datum?.selling_price).format()}</td>
                                    <td className={datum?.stock < 10 ? 'text-red-600' : ''}>{datum?.stock}</td>
                                    <td className='rounded-r-lg'>
                                        <div className='flex items-center gap-2'>
                                            <Edit data={datum} refetch={refetch} />
                                            <Delete productName={datum?.name} productID={datum?.id} refetch={refetch} />
                                        </div>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={6} className='bg-white rounded-lg shadow-md'>
                                    <img src='/empty_illustation.svg' alt='empty data' className='ml-auto mr-auto' />
                                    <div className='text-center font-medium text-lg'>Belum memiliki produk</div>
                                    <div className='text-center text-sm text-gray-500'>Silahkan tambahkan produk</div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>

                {!isFetching && productData?.data?.length ? (
                    <div className='flex justify-between items-center mb-[60px]'>
                        <div className='text-sm text-gray-500'>
                            <div>
                                <Select
                                    type='text'
                                    styles={{
                                        root: {
                                            display: 'inline-block',
                                            width: 70,
                                        },
                                        input: {
                                            textAlign: 'center',
                                        },
                                    }}
                                    value={String(params.per_page)}
                                    data={['10', '25', '50', '100']}
                                    className='mr-2'
                                    onChange={(value) => {
                                        setParams({ ...params, per_page: parseInt(value || '10') });
                                    }}
                                />
                                Menampilkan {productData?.data?.length} data
                            </div>
                        </div>
                        <div>
                            <Pagination
                                value={params.page}
                                total={productData?.last_page}
                                onChange={(value: number) => setParams({ ...params, page: value })}
                            />
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default Products;

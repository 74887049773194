import { Button, Image, Skeleton, Tooltip } from '@mantine/core';
import { FaStoreAlt } from 'react-icons/fa';
import { HiOutlineBuildingStorefront } from 'react-icons/hi2';
import { Link, Navigate } from 'react-router-dom';
import { IMerchant } from '../../interface/Merchant';
import MerchantLayout from '../../layout/merchant';
import MerchantHooks from './MerchantHooks';

const Merchant = () => {
    const { merchantData, isFetching, handleSelectMerchant } = MerchantHooks();

    if (localStorage.getItem('token') === null) {
        return <Navigate to='/login' replace />;
    }

    if (localStorage.getItem('merchantID') !== null) {
        return <Navigate to='/app/dashboard' replace />;
    }

    return (
        <MerchantLayout withLogout>
            <div className='font-semibold text-center text-2xl mb-5'>Daftar Tokomu</div>
            <div className='max-h-[310px] w-[400px] p-2 overflow-auto mb-5'>
                {/* loading */}
                {isFetching && <Skeleton height={66} width='100%' radius='sm' />}

                {/* list merchant */}
                {!isFetching &&
                    merchantData.map((each: IMerchant, key: number) => (
                        <div
                            className='flex justify-between items-center p-2 mb-3 rounded shadow cursor-pointer hover:bg-blue-50'
                            key={key}
                        >
                            <div className='grow flex gap-3' onClick={() => handleSelectMerchant(each.id)}>
                                <Image
                                    width={50}
                                    height={50}
                                    fit='cover'
                                    src={each.image}
                                    withPlaceholder
                                    placeholder={<HiOutlineBuildingStorefront className='text-3xl' />}
                                />
                                <div>
                                    <div className='font-medium mb-1'>{each.name}</div>
                                    <div className='text-xs text-gray-500'>
                                        {each.address.length > 35 ? (
                                            <Tooltip label={each.address}>
                                                <div className='w-fit'>{each.address.substr(0, 35)}...</div>
                                            </Tooltip>
                                        ) : (
                                            <div>{each.address}</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <Link to={`/merchant/edit/${each.id}`}>
                                <Button variant='outline' size='xs'>
                                    Edit
                                </Button>
                            </Link>
                        </div>
                    ))}

                {/* empty state */}
                {!isFetching && merchantData.length === 0 && (
                    <div className='py-5'>
                        <FaStoreAlt className='text-7xl text-blue-700 mx-auto' />
                        <div className='text-center text-lg text-gray-500 font-semibold'>Anda belum memiliki toko</div>
                        <div className='text-xs text-gray-500 text-center'>
                            Silahkan tambahkan toko untuk mulai menggunakan aplikasi
                        </div>
                    </div>
                )}
            </div>

            <div className='flex flex-col gap-2'>
                {localStorage.getItem('role') === 'owner' && (
                    <Link to='/merchant/create'>
                        <Button variant='filled' fullWidth className='bg-blue-700'>
                            Tambah Toko
                        </Button>
                    </Link>
                )}
            </div>
        </MerchantLayout>
    );
};

export default Merchant;

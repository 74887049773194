import { notifications } from '@mantine/notifications';
import { useState } from 'react';
import { generatePath } from 'react-router-dom';
import api from '../../../api';
import { products } from '../../../api/endpoints';

function DeleteHooks(refetch: () => void) {
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(false);

    // function for delete product
    const handleDelete = async (id: number) => {
        setLoading(true);
        const url = generatePath(products.update, { id });
        const payload = {
            merchant_id: localStorage.getItem('merchantID'),
        };
        const res = await api.delete(url, { data: payload });
        setLoading(false);

        if (res.data.status) {
            refetch();
            setModal(false);
        } else {
            notifications.show({
                message: 'Ups, terjadi kesalahan saat delete produk.',
                color: 'red',
            });
        }
    };

    return {
        modal,
        setModal,
        loading,
        handleDelete,
    };
}

export default DeleteHooks;

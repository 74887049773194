import { Navigate, Outlet } from 'react-router-dom';
import Layout from '../layout/dashboard';

const AppPage = () => {
    if (localStorage.getItem('token') === null) {
        return <Navigate to='/login' replace />;
    }

    if (localStorage.getItem('merchantID') === null) {
        return <Navigate to='/merchant' replace />;
    }

    return (
        <Layout>
            <Outlet />
        </Layout>
    );
};

export default AppPage;

import { ReactNode } from 'react';
import { Cashier } from '../../components';
import Content from './Content';
// import SideMenu from './SideMenu';
import SideMenu from './sidemenu';

const Layout: React.FC<{ children?: ReactNode }> = ({ children }) => {
    return (
        <div className='w-full h-screen bg-gray-100 flex gap-x-4 p-4 relative'>
            <SideMenu />
            <div className='grow'>
                {/* <Header /> */}
                <Content>{children}</Content>
            </div>

            <Cashier />
        </div>
    );
};

export default Layout;

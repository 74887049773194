import { Button, Modal } from '@mantine/core';
import { AiOutlinePlus } from 'react-icons/ai';
import CreateHooks from './CreateHooks';
import { GrFormClose } from 'react-icons/gr';
import Form from '../form';

type Props = {
    refetch: () => void;
};

const Create: React.FC<Props> = (props) => {
    const { refetch } = props;
    const { modal, setModal } = CreateHooks();

    return (
        <>
            <Button className='bg-blue-700' leftIcon={<AiOutlinePlus />} onClick={() => setModal(true)}>
                Tambah Produk
            </Button>

            <Modal
                opened={modal}
                onClose={() => setModal(false)}
                withCloseButton={false}
                padding={20}
                radius='md'
                size={400}
            >
                <div className='relative'>
                    <div className='font-semibold text-xl text-center mb-5'>Tambah Produk</div>
                    <GrFormClose
                        className='absolute -right-2 -top-1 text-xl cursor-pointer'
                        onClick={() => setModal(false)}
                    />
                </div>
                <Form refetch={refetch} handleClose={() => setModal(false)} />
            </Modal>
        </>
    );
};

export default Create;

import { useState } from 'react';

function EditHooks() {
    const [modal, setModal] = useState(false);

    return {
        modal,
        setModal,
    };
}

export default EditHooks;

import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
// import { Center, UnstyledButton } from '@mantine/core';
import React from 'react';
import { Link } from 'react-router-dom';

type Props = {
    icon: ReactJSXElement;
    label: string;
    isMinimize: boolean;
    to: string;
    isActive: boolean;
    isShowing: boolean;
};

const MenuItem: React.FC<Props> = (props) => {
    const { icon, label, isMinimize, to, isActive, isShowing } = props;

    if (isMinimize) {
        return (
            <div
                className={`text-2xl p-3 rounded hover:bg-blue-50 cursor-pointer ${
                    isActive && 'bg-blue-700 text-white hover:bg-blue-700 hover:font-medium'
                }`}
            >
                {icon}
            </div>
        );
    }

    return (
        <Link to={to} className={isShowing ? 'block' : 'hidden'}>
            <div
                className={`p-2 cursor-pointer hover:bg-blue-50 rounded text-gray-500 flex items-center gap-3 ${
                    isActive && 'bg-blue-700 text-white hover:bg-blue-700 hover:font-medium'
                }`}
            >
                <div className='text-xl'>{icon}</div>
                <div>{label}</div>
            </div>
        </Link>
    );
};

export default MenuItem;

import { notifications } from '@mantine/notifications';
import { useQuery } from '@tanstack/react-query';
import { generatePath, useNavigate } from 'react-router-dom';
import api from '../../../api';
import { merchant } from '../../../api/endpoints';

function SideMenuContext() {
    const navigate = useNavigate();

    // fetching detail merchant
    const { data: detailMerchant } = useQuery(['detail_merchant'], async () => {
        const url = generatePath(merchant.findByID, { id: localStorage.getItem('merchantID') });
        const res = await api.get(url);

        if (!res?.data?.status) {
            notifications.show({
                message: 'Ups, terjadi kesalahan saat fetch detail merchant.',
                color: 'red',
            });
        }
        return res?.data?.data;
    });

    const handleChooseMerchant = () => {
        navigate('/merchant');
        localStorage.removeItem('merchantID');
    };

    const handleLogout = () => {
        navigate('/login');
        localStorage.clear();
    };

    return {
        detailMerchant,
        handleChooseMerchant,
        handleLogout,
    };
}

export default SideMenuContext;

import { Button, Input } from '@mantine/core';
import { ICategory } from '../../../../interface/Category';
import FormHooks from './FormHooks';

type Props = {
    data?: ICategory;
    setModal: (value: boolean) => void;
    refetch: () => void;
};

const Form: React.FC<Props> = (props) => {
    const { data, refetch, setModal } = props;
    const { register, errors, handleSubmit, loading, onSubmit } = FormHooks(refetch, setModal, data);

    return (
        <form className='p-5' onSubmit={handleSubmit(onSubmit)}>
            <Input.Wrapper
                label='Kategori Produk'
                required
                error={errors?.name?.message ? String(errors?.name?.message) : null}
            >
                <Input
                    {...register('name', {
                        required: {
                            value: true,
                            message: 'Kategori produk wajib diisi',
                        },
                    })}
                    placeholder='input kategori produk'
                    error={errors?.name?.message ? String(errors?.name?.message) : null}
                />
            </Input.Wrapper>
            <div className='flex justify-end gap-2 pt-4'>
                <Button variant='outline' color='red' onClick={() => setModal(false)}>
                    Batal
                </Button>
                <Button type='submit' className='bg-blue-900' loading={loading}>
                    Submit
                </Button>
            </div>
        </form>
    );
};

export default Form;

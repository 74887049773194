import { useState } from 'react';
import { IMerchant } from '../interface/Merchant';

const MerchantContext = () => {
    const [merchants, setMerchants] = useState<Array<IMerchant>>([]);
    const [selectedMerchantId, setSelectedMerchantId] = useState(null);

    return {
        merchants,
        setMerchants,
        selectedMerchantId,
        setSelectedMerchantId,
    };
};

export default MerchantContext;
